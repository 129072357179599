import React from 'react';
import Banner from './content/banner/Banner';
import TheSteps from './content/theSteps/TheSteps';
import StepsSelection from './content/stepsSeclection/StepsSelection';
import KnowUs from './content/knowUs/KnowUs';
import { BackTop } from 'antd';
import Baptism from './content/baptism/Baptism';
import ChristianService from './content/christianServices/ChristianService';

import './NextSteps.less';
import PersonalHelp from './content/personalHelp/PersonalHelp';

interface NextStepsStates {
    isMobile: boolean;
}

const NextSteps = ( isMobile: NextStepsStates) => (
    <div>
        <BackTop />
        <Banner/>
        <TheSteps/>
        <StepsSelection/>
        <KnowUs/>
        <Baptism/>
        <ChristianService/>
        <PersonalHelp/>
    </div>
);

export default NextSteps;
