import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Col, Row, Typography } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import './Worship.less';
import Divider from 'antd/es/divider';
import LegalModal from '../../../../components/globalFooter/legalModal/LegalModal';

const { Title, Text } = Typography;

interface WorshipProps {
}

interface WorshipStates {
    visibleLegalModal: boolean;
    activeTab: string;
}

class Worship extends Component<WorshipProps, WorshipStates> {

    constructor(props: WorshipProps) {
        super(props);
        this.state = {
            visibleLegalModal: false,
            activeTab: '3',
        };
    }

    handleLegal = (tab: string) => {
        this.setState({
            visibleLegalModal: true,
            activeTab: tab
        });
    };

    render() {
        const {visibleLegalModal, activeTab} = this.state;
        return (
            <div  className='home-page-wrapper worship-wrapper'>
                <LegalModal activeTab={activeTab} visibleLegalModal={visibleLegalModal}/>
                <div className='home-page worship'>
                    <OverPack playScale={0.2} >
                        <QueueAnim
                            type='bottom'
                            key='block'
                            leaveReverse
                            component={Row}
                            {...{ className: 'block-wrapper' }}
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Title level={3} className='worship-title'>Gottesdienst</Title>
                                <Text className='block-text'>
                                    Wir kommen zusammen, um gemeinsam Gott zu feiern und ihn anzubeten. In lebensnahen Predigten werden alltagsrelevante Themen aufgegriffen. Dabei ist die Bibel, das Wort Gottes, unsere Grundlage.<br />
                                    <br />
                                    Für Kinder gibt es parallel zum Gottesdienst ein altersgerechtes Angebot. Im Kindergottesdienst bekommen die Kinder ein für sie zugeschnittenes Programm und werden von ausgewählten, fürsorglichen Mitarbeitern betreut.<br />
                                    <br />
                                    Sonntags 10:00 Uhr <Divider type={'vertical'} /> im Gospel Center Böblingen <Divider type={'vertical'}/> Sindelfinger Str. 35</Text>
                            </Col>
                        </QueueAnim>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Worship;
