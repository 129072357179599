import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Col, Row, Typography } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import './Welcome.less';

const { Title, Text } = Typography;


class Welcome extends Component {

    render() {

        return (
            <div  className='home-page-wrapper welcome-wrapper'>
                <div className='home-page welcome'>
                    <div className='title-wrapper'>
                        <Title level={1}>Willkommen</Title>
                    </div>
                    <OverPack  playScale={0.2}>
                        <QueueAnim
                            type='bottom'
                            key='block'
                            leaveReverse
                            component={Row}
                            {...{ className: 'block-wrapper' }}
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                className: 'block',
                                md: 24,
                                xs: 24}
                            }>
                                <Title level={3} className='welcome-title'>Herzlich willkommen im Gospel Center</Title>
                                <Text className='block-text'>Egal, ob du mit „Kirche“ etwas anfangen kannst oder nicht, schon lange glaubst oder dir einen Neustart wünschst, du bist hier herzlich willkommen! <br />
                                    Wir glauben, dass Kirche ein Ort sein sollte voller Leben, Begeisterung und der verändernden Gegenwart Gottes.<br />
                                    Wir wollen eine Gemeinde der Liebe Jesu sein. In allem, was wir tun, wollen wir, dass Menschen ihr gottgegebenes Potential entwickeln und ausschöpfen können.<br />
                                    Jeder soll ein erfülltes Leben haben, so wie Gott es sich wünscht.</Text>
                            </Col>
                        </QueueAnim>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default Welcome;
