import React, { Component } from 'react';
import { Row, Col, List, Avatar, Button, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import { CheckCircleTwoTone } from '@ant-design/icons';

import './Special.less';

import special from '../../../../assets/images/Mockup_weiss-1030x1030.png';

const { Text } = Typography;

const data = [
    {
        title: '14 Zeitschriften gratis lesen',
        description: 'Diese Zeitschriften sind dabei: Family FIPS, KLÄX, Teensmag, DRAN, Family, FamilyNEXT, JOYCE, ' +
            'MOVO, AUFATMEN, LebensLauf, Faszination Bibel, lebenslust, ' +
            '3E und P&S – Magazin für Psychotherapie und Seelsorge.'
    },
    {
        title: 'Kostenloser App-Zugang',
        description: 'Die digitalen Ausgaben der Zeitschriften werden für ' +
            'einen begrenzten Zeitraum kostenfrei zur Verfügung stehen.'
    },
    {
        title: 'Unverbindlich, endet automatisch',
        description: 'Das Angebot besteht ohne Verpflichtung zum Kauf oder Abonnement. ' +
            'Sie geben lediglich Ihre Adressdaten an. Eine Kündigung ist nicht nötig.'
    },
];

class Special extends Component {
    render() {
        return (
            <div className='home-page-wrapper special--page' id='special'>
                <div className='page' >
                    <h2>Aktion</h2>
                    <div className='title-line-wrapper special-line'>
                        <div className='title-line' />
                    </div>
                    <ScrollOverPack component={Row} className='special--page-content' playScale='0.4'>
                        <QueueAnim
                            component={Col}
                            componentProps={{ xs: 24, md: 12 }}
                            className='special--page-special'
                            key='left'
                            type='bottom'
                            leaveReverse
                        >
                            <img key='image' src={special} alt='special'/>
                        </QueueAnim>
                        <QueueAnim
                            component={Col}
                            componentProps={{ xs: 24, md: 12 }}
                            className='special--page-specialText'
                            key='right'
                            type='bottom'
                            leaveReverse
                        >
                            <h3 key='h2'>Christliche Zeitschriften digital lesen – kostenfrei und unverbindlich</h3>
                            <Text key='text2'>
                                Veranstaltungen, Gottesdienste, Kleingruppen – all das darf vorerst nicht mehr stattfinden.
                                Doch Ermutigung für Leben und Glauben brauchen wir gerade in diesen Tagen.
                                Wir wollen Sie unterstützen, die vermehrt daheim verbrachte Zeit möglichst bereichernd zu gestalten.
                                Persönlich, als Familie und als Gemeinde.
                            </Text>
                            <List
                                className='special--page-list'
                                key='list'
                                itemLayout='horizontal'
                                dataSource={data}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={
                                                <Avatar
                                                    className='special--page-avatar'
                                                    icon={<CheckCircleTwoTone twoToneColor='#52c41a' />
                                                    }
                                                />}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    </List.Item>
                                )}
                            />
                            <Button
                                className='special--page-button'
                                key='shop'
                                size={'large'}
                                type={'primary'}
                                onClick={() => {
                                    window.open('https://microshop.bundes-verlag.net/christliche-magazine-gratis-lesen/#bestellen', '_blank');
                                }}>
                                Jetzt gratis lesen
                            </Button>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default Special;


