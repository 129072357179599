import React from 'react';
import Content from './content/Content';

import './Datenschutz.less';


const Datenschutz = () => (
    <div>
        <Content />
    </div>
);

export default Datenschutz;
