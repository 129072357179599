import React from 'react';
//import { GoogleMap, LoadScript } from '@react-google-maps/api';

//import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

import './Map.less';



class Map extends React.PureComponent {

    /*CMap = withScriptjs(withGoogleMap((props: { children: any; }) =>
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{ lat: 48.688542, lng: 9.01064580000002 }}
        >
            {props.children}
        </GoogleMap>
    ));
    */

    apiKey = "AIzaSyD9K8G4czPB76jbZUJAIQA3zEQJskYiG30";
    containerClassName = "h400pxl";
    center = { lat: 48.688542, lng: 9.0106458 };
    zoom = 10;

    render() {
        return (
            <div>
                {/*<LoadScript
                    googleMapsApiKey = { this.apiKey }
                    preventGoogleFontsLoading = { false }
                >
                    <GoogleMap
                        mapContainerClassName = { this.containerClassName }
                        center = { this.center }
                        zoom = { this.zoom }
                        
                    >
                        
                        <></>
                    </GoogleMap>
                </LoadScript>
                {/*
                <this.CMap
                    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyD9K8G4czPB76jbZUJAIQA3zEQJskYiG30&v=3.exp&libraries=geometry,drawing,places'
                    loadingElement={<div className="h100pct" />}
                    containerElement={<div className="h400pxl" />}
                    mapElement={<div className="h100pct" />}
                >
                    <Marker
                        position={{ lat: 48.688542, lng: 9.01064580000002 }}
                    />
                </this.CMap>
                 */}
            </div>
        );
    }
}

export default Map;
