import React from 'react';
//import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

import './Map.less';



class Map extends React.PureComponent {

    /*
    CMap = withScriptjs(withGoogleMap((props: { children: any; }) =>
        <GoogleMap
            defaultZoom={13}
            defaultCenter={{ lat: 48.675161, lng: 9.026541 }}
        >
            {props.children}
        </GoogleMap>
    ));
    */

    render() {
        return (
            <div>
                {/*
                <this.CMap
                    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyD9K8G4czPB76jbZUJAIQA3zEQJskYiG30&v=3.exp&libraries=geometry,drawing,places'
                    loadingElement={<div className="h100pct" />}
                    containerElement={<div className="h400pxl" />}
                    mapElement={<div className="h100pct" />}
                >
                    <Marker
                        position={{ lat: 48.675161, lng: 9.026541 }}
                    />
                </this.CMap>
                */}
            </div>
        );
    }
}

export default Map;
