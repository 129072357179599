import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { Button, notification } from 'antd';
import moment  from 'moment';
import LegalModal from '../globalFooter/legalModal/LegalModal';
// @ts-ignore
import cookie from 'react-cookie';

import './CookieBanner.less';
import cookieImg from './cookieImg';


interface CookieBannerProps {
}
interface CookieBannerStates {
    showCookieLayer: boolean;
    visibleLegalModal: boolean;
    activeTab: string;
}

class CookieBanner extends Component<CookieBannerProps, CookieBannerStates> {

    constructor(props: CookieBannerProps) {
        super(props);
        this.state = {
            showCookieLayer: true,
            visibleLegalModal: false,
            activeTab: '1'
        };
    }

    handleLegal = (tab: string) => {
        this.setState({
            visibleLegalModal: true,
            activeTab: tab
        });
    };

    componentDidMount = () => {
        const key = `open${Date.now()}`;
        const btn = (
            <div>
                <Button type='link' onClick={() => this.handleLegal('2')}>
                    Mehr Details
                </Button>
                <Button type='primary' onClick={() => {
                    this.setPersistCookieLayerStatusTechnicalOnly();
                    notification.close(key);
                }}>
                    Nur technisch notwendige
                </Button>
                <Button type='primary' onClick={() => {
                    this.setPersistCookieLayerStatusAll();
                    notification.close(key);
                }}>
                    Alle
                </Button>
            </div>
        );

        const icon = (
            <Icon component={cookieImg}/>
        );
        const showCookieLayer = CookieBanner.getCookieStatus();

        this.setState ({
            showCookieLayer: showCookieLayer
        });

        if (showCookieLayer) {
            const args = {
                message: 'Verwendung von Cookies',
                description: [ <div><span>Wir möchten unsere Homepage benutzerfreundlicher gestalten und kontinuierlich verbessern.
                    Hierzu verwenden wir Cookies, die in ihrem Browser gespeichert werden. Bitte wählen sie, welche Art von Cookies sie akzeptieren wollen!<br/><br/>
                    "Alle" bedeutet: Cookies zu Analysezwecken und technisch notwendige Cookies.<br/><br/>
                    "Nur technisch notwendige" bedeutet: Ausschließslich Cookies die zum Betrieb dieser Homepage notwendig sind.<br/><br/>
                    Diese Entscheidung bleibt selbst als Cookie maximal 6 Monate in ihrem Browser gespeichert oder bis sie dessen Cookies löschen.<br/><br/>
                    Das schließen dieser Meldung kommt einer Auswahl von "Nur technisch notwendige" gleich.  
                    </span></div> ],
                duration: 0,
                btn,
                icon,
                key,
                onClose: () => {
                    this.setPersistCookieLayerStatusTechnicalOnly();
                },
            };
            notification.config({
                placement: 'bottomLeft',
            });
            notification.open(args);
        }
    };

    setPersistCookieLayerStatusAll() {
        cookie.save('cookie-layer-status', 'all', { path: '/', expires: moment().add(6, 'months').toDate()});
        this.setState ({
            showCookieLayer: false,
            visibleLegalModal: false,
        });
    }

    setPersistCookieLayerStatusTechnicalOnly() {
        cookie.save('cookie-layer-status', 'technicalOnly', { path: '/', expires: moment().add(6, 'months').toDate()});
        this.setState ({
            showCookieLayer: false,
            visibleLegalModal: false,
        });
    }

    static getCookieStatus() {
        return cookie.load('cookie-layer-status') === undefined;
    }


    render() {
        const { visibleLegalModal, activeTab } = this.state;
        return (
            <div>
                <LegalModal activeTab={activeTab} visibleLegalModal={visibleLegalModal}/>
            </div>
        );
    }
}

export default CookieBanner;
