import React from 'react';
import { BackTop } from 'antd';
import Banner from './Content/banner/Banner';
import Info from './Content/info/Info';
import Donate from './Content/donate/Donate';

import NoProfit from './Content/noProfit/NoProfit';

import './Give.less';


interface GiveStates {
    isMobile: boolean;
}

const Give = (isMobile: GiveStates) => (
    <div>
        <BackTop />
        <Banner />
        <Info />
        <Donate />
        <NoProfit />
    </div>
);

export default Give;
