import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { DownOutlined } from '@ant-design/icons';
import { Statistic, Typography } from 'antd';
import moment from 'moment';

import './Banner.less';
import logo from '../../../../assets/images/GC_white.png';


interface BannerProps {
}
interface BannerState {
    eventName: string;
}

class Banner extends Component<BannerProps, BannerState> {

    deadline = moment().set({'month': 11, 'date': 24, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0});

    constructor(props: BannerProps) {
        super(props);
        this.state = {
            eventName: ''
        };
    }

    componentDidMount = () => {
        this.getSpecialEvent(moment());
    };

    getSpecialEvent(today: moment.Moment) {
        if (today.month() === 11 && today.date() <= 29) {
            if (today.date() >= 24 && today.date() <= 29) {
                this.setState({
                    eventName: 'christmas'
                });
            } else {
                this.setState({
                    eventName: 'advent'
                });
            }
        } else {
            this.setState({
                eventName: 'default'
            });
        }
    }

    render() {
        const { Countdown } = Statistic;
        const { Title, Text } = Typography;
        const deadline = moment().set({'month': 11, 'date': 24, 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0});

        return (
            <div className={'banner banner-' + this.state.eventName}>
                {this.state.eventName === 'default' && (
                    <QueueAnim
                        key='QueueAnimDefault'
                        type={['bottom', 'top']}
                        delay={200}
                        {...{ className: 'banner-text-wrapper' }}
                    >
                        <div key='title' className='banner-title' >
                            <img src={logo} width='100%' alt='img' />
                        </div>
                        <div key='content' className='banner-content'>
                            <b>Gospel Center</b> <br/>
                            Böblingen
                        </div>
                    </QueueAnim>
                )}
                {this.state.eventName === 'advent' && (
                    <QueueAnim
                        key='QueueAnimAdvent'
                        type={['bottom', 'top']}
                        delay={200}
                        {...{ className: 'banner-text-wrapper' }}
                    >
                        <div key='content' className='banner-content'>
                            a thrill of Hope <br/>
                            Christmas
                        </div>
                        <div key='countdown'>
                            <Title className='white' level={4}>Heiligabend Gottesdienst </Title>
                            <Text className='white'>Am 24. Dezember um 16:00 Uhr <br/></Text>
                            <Countdown
                                className='christmas-countdown'
                                valueStyle={{color: 'white'}}
                                title='Weihnachten in'
                                value={deadline.valueOf()}
                                format='DD [Tage] HH:mm:ss'
                            />
                        </div>
                    </QueueAnim>
                )}
                {this.state.eventName === 'christmas' && (
                    <QueueAnim
                        key='QueueAnimChristmas'
                        type={['bottom', 'top']}
                        delay={200}
                        {...{ className: 'banner-text-wrapper' }}
                    >
                        <div key='content' className='banner-content'>
                            Jesus is born!
                        </div>
                        {moment() < moment().set({'month': 11, 'date': 24, 'hour': 16, 'minute': 0, 'second': 0, 'millisecond': 0}) &&
                            <div key='subcontent' className='banner-subconten'>
                                <Title className='white' level={4}>Heiligabend Gottesdienst </Title>
                                <Text className='white'>Am 24. Dezember um 16:00 Uhr <br/></Text>
                            </div>
                        }
                        {moment() > moment().set({'month': 11, 'date': 24, 'hour': 16, 'minute': 0, 'second': 0, 'millisecond': 0}) &&
                            <div key='subcontent' className='christmas-whishes-subconten'>
                                <Title className='white christmas-whishes' level={4}>Merry Christmas</Title>
                            </div>
                        }
                    </QueueAnim>
                )}
                <TweenOne
                    animation={{
                        y: '-=20',
                        yoyo: true,
                        repeat: -1,
                        duration: 1000,
                    }}
                    className='banner-icon'
                    key='icon'
                >
                    <DownOutlined />
                </TweenOne>
            </div>
        );
    }
}

export default Banner;
