import React, { Component } from 'react';
import { Row, Timeline } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './History.less';

interface HistoryProps {
}

interface HistoryStates {
    isMobile: boolean;
}

class History extends Component<HistoryProps, HistoryStates> {
    render() {
        return (
            <div className='history'>
                <div className='page' >
                    <div className='history--header-wrapper'>
                        <QueueAnim className='history--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Über uns</h2>
                            <div key='line' className='title-line-wrapper'>

                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <h3 key='headline'>Geschichte</h3>
                            <Timeline className='timeline' key='timeline' mode='alternate' pending='Wir bauen gemeinsam am Reich Gottes'>
                                <Timeline.Item>April 2004 <br/>
                                    Die Gemeinde wird durch die Eheleute Helmut und Lydia
                                    Stäbler als Biblische Glaubens Gemeinde Böblingen e.V. (BGG Böblingen) gegründet
                                    und ist zunächst eine Tochtergemeinde der Biblischen Glaubens Gemeinde
                                    Stuttgart e.V. (BGG Stuttgart)
                                </Timeline.Item>
                                <Timeline.Item>September 2004 <br/>
                                    Die ersten Räumlichkeiten sind in der Rudolf-Diesel Straße, Böblingen mit
                                    14-tägigem Gottesdienst
                                </Timeline.Item>
                                <Timeline.Item>Januar 2007<br/>
                                    Die BGG Böblingen wird offiziell als eigenständige Gemeinde von der BGG Stuttgart
                                    ausgesandt
                                </Timeline.Item>
                                <Timeline.Item>Dezember 2010<br/>
                                    Die Gemeinde wird von Biblische Glaubens Gemeinde Böblingen e.V. in Gospel Center
                                    Böblingen e.V. umbenannt <br/><br/>
                                    Ein Wunder Gottes: Kauf des neuen Gemeindezentrums in der Sindelfinger Straße 35,
                                    Böblingen
                                </Timeline.Item>
                                <Timeline.Item>Januar 2011<br/>
                                    Auszug aus den Räumlichkeiten der Rudolf-Diesel-Straße
                                </Timeline.Item>
                                <Timeline.Item>Februar bis Mai 2011<br/>
                                    Übergangsweise Gottesdienst in den Räumen der Freien evangelischen Gemeinde
                                    Böblingen auf der Hulb
                                </Timeline.Item>
                                <Timeline.Item>März 2011<br/>
                                    Beginn des Umbaus unseres Gemeindezentrums, Bauarbeiten mit vielen freiwilligen
                                    Helfern starten
                                </Timeline.Item>
                                <Timeline.Item>Januar 2012<br/>
                                    Das Gospel Center ist fertiggestellt. Einzug des Gemeindebüros
                                </Timeline.Item>
                                <Timeline.Item>29.Juni.-1.Juli. 2012<br/>
                                    Feierliche Einweihung des Gospel Center
                                </Timeline.Item>
                                <Timeline.Item>2013<br/>
                                    Aufnahme als Mitglied in die Evangelische Allianz Böblingen
                                </Timeline.Item>
                                <Timeline.Item>2014<br/>
                                    10-jähriges Jubiläum des Gospel Center Böblingen e.V.
                                </Timeline.Item>
                                <Timeline.Item>9. März 2017<br/>
                                    Unser Gemeindegründer und Seniorpastor Helmut Stäbler ist verstorben
                                </Timeline.Item>
                                <Timeline.Item>Juni 2019<br/>
                                    Renovierung des Foyers
                                </Timeline.Item>
                                <Timeline.Item>Juli 2020<br/>
                                    Unsere Seniorpastorin Lydia Stäbler geht in den wohlverdienten Ruhestand<br/>
                                    Martin Preusche und Micha Gleich übernehmen die Gemeindeleitung
                                </Timeline.Item>

                            </Timeline>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default History;
