import React, { Component } from 'react';
import  GlobalHeader  from '../components/globalHeader/GlobalHeader';
import  GlobalFooter  from '../components/globalFooter/GlobalFooter';
import LandingPage from './landingPage/LandingPage';
import AboutUs from './aboutUs/AboutUs';
import RoyalRangers from './royalRangers/RoyalRangers';
import NextSteps from './nextSteps/NextSteps';
import Shop from './shop/Shop';
import CookieBanner from '../components/cookieBanner/CookieBanner';
import GlobalNews from '../components/globalNews/GlobalNews';
import Page404 from './page404/Page404';
import { Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import de_DE from 'antd/lib/locale-provider/de_DE';
import moment from 'moment';
import 'moment/locale/de';

import Give from './give/Give';

import '../assets/App.css';

moment.locale('de');

class App extends Component {


    render() {
        return (
            <ConfigProvider  locale={de_DE}>
                <div className='templates-wrapper'>
                    <CookieBanner />
                    <GlobalNews />
                    <GlobalHeader/>
                    <Switch>
                        <Route path='/' exact component={LandingPage} />
                        <Route path='/aboutus' exact component={AboutUs} />
                        <Route path='/royalrangers' exact component={RoyalRangers} />
                        <Route path='/nextsteps' exact component={NextSteps} />
                        <Route path='/give' exact component={Give} />
                        <Route path='/shop' exact component={Shop} />
                        <Route component={Page404} />
                    </Switch>
                    <GlobalFooter />
                </div>
            </ConfigProvider >
        );
    }
}

export default App;
