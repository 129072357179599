import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './TheSteps.less';

const { Text } = Typography;

interface TheStepsProps {
}

interface TheStepsStates {
    isMobile: boolean;
}

class TheSteps extends Component<TheStepsProps, TheStepsStates> {
    render() {
        return (
            <div className='theSteps'>
                <div className='page' >
                    <div className='theSteps--header-wrapper'>
                        <QueueAnim className='theSteps--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Next Steps</h2>
                            <div key='line' className='title-line-wrapper'>
                                <div
                                    className='title-line'
                                    style={{ transform: 'translateX(-64px)' }}
                                />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3' className='theSteps--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>Wir glauben, dass jeder Mensch einen nächsten Schritt in seinem Leben mit Jesus hat.
                                    Wir wollen jedem einzelnen helfen seinen nächsten Schritt zu entdecken,
                                    so dass jeder seinen Weg mit Gott findet und einen Unterschied in dieser Welt machen kann.
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default TheSteps;
