import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import ScrollParallax from 'rc-scroll-anim/lib/ScrollParallax';

import './Banner.less';

interface BannerProps {
}

interface BannerStates {
    isMobile: boolean;
}

class Banner extends Component<BannerProps, BannerStates> {

    constructor(props: BannerProps) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    };

    resize() {
        this.setState({isMobile: window.innerWidth <= 768});
    }
    render() {
        return (
            <div>
                <div className='nextSteps-wrapper nextStepsBanner-wrapper' id='nextStepsBanner'>
                    <div className='nextStepsBanner-bg-wrapper'>
                        <ScrollParallax location='nextStepsBanner' className='nextStepsBanner-bg' animation={{ playScale: [1, 1.5], rotate: 0 }} />
                    </div>
                    <QueueAnim className={`nextStepsBanner page`} type='alpha' delay={150}>
                        {this.state.isMobile && (
                            <div className='img-wrapper' key='image'>
                            </div>)}
                        {!this.state.isMobile && (
                            <div className='img-wrapper' key='image'>
                            </div>)}
                    </QueueAnim>
                </div>

            </div>
        );
    }
}

export default Banner;
