import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';

import './RRGermany.less';

import rrLogo from '../../../../assets/images/royal-ranger/Logo_Royal_Rangers.svg';

interface RRGermanyProps {
}


class RRGermany extends Component<RRGermanyProps> {
    render() {
        return (
        <div className='rr-germany-layout-wrapper rr-germany-serve-wrapper'>
            <h2 key='h2'>Royal Rangers Detuschland</h2>
            <div className='title-line-wrapper rr-germany-line'>
                <div className='title-line' />
            </div>
            <OverPack className='rr-germany-layout' playScale={0.4}>
                <QueueAnim className='rr-germany-serve' type='bottom' key='rr-germany-func' ease='easeOutQuart' leaveReverse>
                    <QueueAnim
                        key='content'
                        component={Row}
                        type='bottom'
                        componentProps={{ gutter: 96 }}
                    >
                        <Col span={24} className='col' key='1'>
                            <QueueAnim
                                type='bottom'
                                className='content-wrapper rr-germany-hover'
                                onClick={() => { window.open('https://royal-rangers.de/', '_blank'); }}
                            >
                                <img key='image' className='image icon-hover' src={rrLogo} alt='' />
                                <p>
                                    Die Royal Rangers sind ein begeisterndes und aktionsreiches Programm, das Kindern und Jugendlichen Spaß macht, sie motiviert und voranbringt.<br/>
                                    <br/>
                                    Es sind Christliche Pfadfinder im Alter von 6-17 Jahren. Sie treffen sich regelmäßig in ihrem Stammposten, wo sie Abenteuer erleben, Freundschaften pflegen, singen und lachen. Jeder der Teilnehmer gehört zu einem festen Team aus sechs bis acht Kindern.<br/>
                                    <br/>
                                    Royal Rangers sind am liebsten draußen. Sie lieben es, Feuer zu machen und bei Wind und Wetter mit Karte und Kompass durchs Gelände zu navigieren. Ältere Teilnehmer gestalten bereits mit und lernen dabei selbst Verantwortung zu übernehmen.<br/>
                                    <br/>
                                    Es ist so schön unterwegs zu sein. Jedes Jahr gibt es bei Camps und Fahrten die Chance, unvergessliche Tage zu erleben. Auf Camps gibt es ein packendes Programm und man lernt neue Freunde kennen; auf Hajks, hat man alles nötige bei sich und erkundet die Welt zu Fuß mit dem Fahrrad oder im Kanu.<br/>
                                </p>
                                <h4 key='h4' className='rr-germany-link'>Weiter zur Royal Rangers Deutschland Seite</h4>
                            </QueueAnim>
                        </Col>
                    </QueueAnim>
                </QueueAnim>
            </OverPack>
        </div>);
    }
}

export default RRGermany;
