import React, { Component } from 'react';
import { Typography } from 'antd';
import Disclaimer from './disclaimer/Disclaimer';

import './Content.less';

const { Title, Text } = Typography;



class Content extends Component {

    render() {

        return (
            <div className='impressum__wrapper'>
                <Title level={2} className='impressum-title'>Impressum</Title>
                <Title level={3}>Betreiber der Seite</Title>
                <Text className='impressum__margin-bottom'>Gospel Center Böblingen e.V. <br />
                    Sindelfingerstr. 35<br />
                    71032 Böblingen<br />
                    Telefon: 07031 - 412106<br />
                    Fax: 07031 - 417006<br />
                    E-Mail:  <a href='mailto:info@gospelcenter.de'>info@gospelcenter.de</a><br />
                    Vereinsregisters: 241620<br />
                    Registergericht: Amtsgericht Stuttgart
                </Text>
                <Title level={3}>Realisierung der Seite</Title>
                <Text className='impressum__margin-bottom'>Gospel Center Böblingen e.V. <br />
                    Sindelfingerstr. 35<br />
                    71032 Böblingen</Text>
                <Title level={3} className='content-title'>Vertretungsberechtigte</Title>
                <Text className='impressum__margin-bottom'>Martin Preusche<br />
                    1. Vorstand<br />
                    E-Mail:  <a href='mailto:martin.preusche@gospelcenter.de'>martin.preusche@gospelcenter.de</a><br />
                    Telefon: 07031 - 7332946</Text>
                <Disclaimer/>
            </div>
        );
    }
}

export default Content;
