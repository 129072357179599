import React from 'react';
import { Row, Col,  Typography } from 'antd';

import './PersonalHelp.less';

const { Text } = Typography;

export default function PersonalHelp() {
    return (
        <div className='home-page-wrapper personal-help' id='personalHelp'>

            <h2>Persönliche Hilfe</h2>
            <div className='page' >
                <div className='title-line-wrapper personal-help-line'>
                    <div className='title-line' />
                </div>

                    <Row key='1' justify='center' align='middle'>
                        <Col key='1'>
                            <Text>
                                Wir glauben an einen Gott, der uns von den Lasten des Alltags, unseren Sorgen und Nöten befreien möchte. <br/>
                                Wenn du in einem dieser Bereiche Hilfe benötigst, dann wende dich gerne an uns. <br/>
                                Wir nehmen uns gerne die Zeit mit dir zu sprechen, zu beten und dir in schwierigen Zeiten beizustehen.<br/>
                                Schreibe uns einfach eine E-Mail oder komm nach einem Gottesdienst auf uns zu.<br/>
                            </Text>
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                    
                    <Row key='2' justify='center' align='middle'>
                        <Col key='2'>
                    
                        </Col>
                    </Row>
                    
                    <Row key='3' justify='center' align='middle'>
                        <Col key='1'>
                            <Text >
                                Martin Preusche<br/>
                            </Text>
                            <a href='mailto:martin.preusche@gospelcenter.de'>martin.preusche@gospelcenter.de</a><br/><br/>
                        </Col>
                    
                        <Col key='2'>
                        </Col>
                    
                        <Col key='3' md={6} xs={6}>
                            <Text >
                                Micha Gleich<br/>
                            </Text>
                            <a href='mailto:micha.gleich@gospelcenter.de'>micha.gleich@gospelcenter.de</a><br/><br/>
                        </Col>
                    </Row>
            </div>
        </div>
    );
}
