import Impressum from '../../impressum/Impressum';
import Datenschutz from '../../datenschutz/Datenschutz';
import { Button, Modal, Tabs } from 'antd';
import React, { Component } from 'react';
import Coronavirus from '../../coronavirus/Coronavirus';

const { TabPane } = Tabs;

interface LegalModalProps {
    activeTab: string;
    visibleLegalModal: boolean;
}
interface LegalModalStates {
    visibleLegalModal: boolean;
    activeTab: string;
}

class LegalModal extends Component<LegalModalProps, LegalModalStates> {

    constructor(props: LegalModalProps) {
        super(props);
        this.state = {
            visibleLegalModal: props.visibleLegalModal,
            activeTab: props.activeTab
        };
    }

    componentWillReceiveProps(props: LegalModalProps) {
        this.setState({
            visibleLegalModal: props.visibleLegalModal,
            activeTab: props.activeTab
        });
    }

    handleOk = () => {
        this.setState({
            visibleLegalModal: false,
        });
    };

    handleTab = (key: string) => {
        this.setState({
            activeTab: key
        });
    };

    handleLegal = (tab: string) => {
        this.setState({
            visibleLegalModal: true,
            activeTab: tab
        });
    };

    render() {
        const { visibleLegalModal, activeTab } = this.state;
        return (
            <Modal visible={visibleLegalModal}
                   className='legal-modal'
                   title={
                       <Tabs activeKey={activeTab} onChange={this.handleTab}>
                           <TabPane tab='Impressum' key='1' >
                               <Impressum/>
                           </TabPane>
                           <TabPane tab='Datenschutz' key='2'>
                               <Datenschutz/>
                           </TabPane>
                           <TabPane tab='Coronavirus' key='3'>
                               <Coronavirus/>
                           </TabPane>
                       </Tabs>
                   }
                   width={768}
                   onCancel={this.handleOk}
                   footer={[
                       <Button type='primary' onClick={this.handleOk}>
                           Schließen
                       </Button>
                   ]}
            />
        );
    }
}

export default LegalModal;
