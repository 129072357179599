import React from 'react';
import { BackTop } from 'antd';
import Banner from './content/banner/Banner';
import BooksTable from './content/booksTable/BooksTable';
import BooksTableOnline from './content/booksTableOnline/BooksTableOnline';
import Special from './content/special/Special';

import './Shop.less';

interface ShopStates {
    isMobile: boolean;
}

const Shop = (isMobile: ShopStates) => (
    <div>
        <BackTop />
        <Banner/>
        <BooksTable/>
        <BooksTableOnline/>
        <Special/>
    </div>
);

export default Shop;
