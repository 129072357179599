import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './OurTribe.less';

const { Text } = Typography;

interface OurTribeProps {
}


class OurTribe extends Component<OurTribeProps> {
    render() {
        return (
            <div className='ourTribe'>
                <div className='page'>
                    <div className='ourTribe--header-wrapper'>
                        <QueueAnim className='ourTribe--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Royal Rangers Böblingen</h2>
                            <div key='line' className='title-line-wrapper'>
                                <div
                                    className='title-line'
                                />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3' className='ourTribe--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
 
                                <Text className='block-text'>
                                    Der Stamm 407 Böblingen wurde im Jahr 2009 gegründet. Derzeit haben wir über 100 aktive Mitglieder. Viele unserer Leiter haben die einzelnen Stufen dieser pfadfinderischen Kinder- und Jugendarbeit selbst durchlaufen und geben ihre langjährige Erfahrung und immer noch anhaltende Begeisterung an ihre Teams weiter.<br />
                                    <br />
                                    Wir lieben es draußen gemeinsam unterwegs zu sein und die Welt zu entdecken. Dazu treffen wir uns regelmäßig und ausschließlich draußen, denn Pfadfinder gehören nun mal in die freie Wildbahn. Unsere Kinder lernen dabei von Anfang an, dass es kein schlechtes Wetter sondern nur falsche Kleidung gibt.<br />
                                    <br />
                                    In verschiedenen Altersstufen auf einem Wachstumspfad sind die Kinder und Jugendlichen in Teams von Gleichaltrigen zusammen und genießen bei altersentsprechenden Aufgaben und Herausforderungen Gemeinschaft miteinander. Jedes Team hat seinen eigenen Teamplatz und lernt im Laufe der Jahre die verschiedensten Pfadfinderskills, neue Freunde, aber auch die Gemeinschaft mit Gott, kennen. Hierbei gehören für uns Lieder singen, Feuer machen, in Zelten schlafen und über dem Feuer kochen zum Alltag. Darüber hinaus machen wir auch große Aktionen und gehen auf Hajk, Kanufahrt, Sommer- und Wintercamp aber auch in die Berge.<br />
                                    <br />
                                    Komm einfach mal vorbei, du bist immer herzlich eingeladen<br />
                                    <br />
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default OurTribe;
