import React, { Component } from 'react';
//import { IntlProvider } from 'react-intl';
//import translations from '../i18n/locales';
import App from './App';
import { HashRouter } from 'react-router-dom';


class AppWrapper extends Component {
  render() {
    //const locale = window.location.search.replace('?locale=', '') || 'de';
    //const messages = translations[locale];

      return (
      //<IntlProvider locale={locale} key={locale} messages={messages}>
        <HashRouter>
          <App/>
        </HashRouter>
      //</IntlProvider>
    );
  }
}

// @ts-ignore
export default AppWrapper;
