import React, { Component } from 'react';
import { Row, Col } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import svgBgToParallax from '../../../shared/utils/util';
import UtBesTOkoZsBUxPqfDlZ from '../../../../assets/svg/UtBesTOkoZsBUxPqfDlZ.svg';
import VrADJaRPMnFjmtmIhObV from '../../../../assets/svg/VrADJaRPMnFjmtmIhObV.svg';
import MnLEmwjipfhzPUmBJnJE from '../../../../assets/svg/MnLEmwjipfhzPUmBJnJE.svg';
import dyNuxLOZtvjoHSVisbhQ from '../../../../assets/svg/dyNuxLOZtvjoHSVisbhQ.svg';


import './Vision.less';


const svgBgChild = [
    (
        <svg width='100%' height='100%' viewBox='0 0 1401 1109' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' preserveAspectRatio='xMidYMid slice' >
            <g transform='translate(-79.000000, -21.000000)'>
                <circle id='Oval-13' stroke='none' fill='#EBEDF0' fillRule='evenodd' cx='98.5' cy='98.5' r='98.5' />
                <circle id='Oval-13' stroke='#EBEDF0' strokeWidth='16' fill='none' cx='1402' cy='151' r='70' />
                <path d='M385.032144,960.394832 L394.316344,976.475539 C394.868629,977.432124 394.540879,978.655305 393.584293,979.20759 C393.280255,979.383126 392.935367,979.475539 392.584293,979.475539 L374.015893,979.475539 C372.911323,979.475539 372.015893,978.580108 372.015893,977.475539 C372.015893,977.124466 372.108305,976.779577 372.283842,976.475539 L381.568042,960.394832 C382.120327,959.438247 383.343508,959.110497 384.300093,959.662781 C384.604131,959.838318 384.856607,960.090794 385.032144,960.394832 Z' id='Polygon-2' stroke='none' fill='#BCBEC0' fillRule='evenodd' transform='translate(383.300093, 970.709623) rotate(70.000000) translate(-383.300093, -970.709623) ' />
                <path d='M545.537489,211.431472 L552.545207,223.569196 C553.097492,224.525781 552.769741,225.748962 551.813156,226.301246 C551.509118,226.476783 551.164229,226.569196 550.813156,226.569196 L536.79772,226.569196 C535.693151,226.569196 534.79772,225.673765 534.79772,224.569196 C534.79772,224.218122 534.890133,223.873234 535.06567,223.569196 L542.073387,211.431472 C542.625672,210.474887 543.848853,210.147137 544.805438,210.699421 C545.109477,210.874958 545.361952,211.127434 545.537489,211.431472 Z' id='Polygon-2' stroke='none' fill='#BCBEC0' fillRule='evenodd' transform='translate(543.805605, 218.500167) rotate(90.000000) translate(-543.805605, -218.500167) ' />
                <g id='Group-26' transform='translate(146.000000, 338.000000)' fill='#9D9D9D'>
                    <image xlinkHref={UtBesTOkoZsBUxPqfDlZ} />
                </g>
            </g>
        </svg>
    ),
    (
        <svg width='100%' height='920px' viewBox='0 0 1311 920' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Group-29' transform='translate(283.000000, 920.000000)' fill='#2F54EB'>
                <image xlinkHref={VrADJaRPMnFjmtmIhObV} />
            </g>
            <circle id='Oval-8' stroke='#666666' strokeWidth='4' opacity='0.95' cx='1096' cy='11' r='11' />
            <circle id='Oval-8' stroke='#666666' strokeWidth='4' cx='11' cy='667' r='11' />
            <g id='Group-11' transform='translate(1207.000000, 419.000000)' fill='#D7D7D7'>
                <image xlinkHref={MnLEmwjipfhzPUmBJnJE} />
            </g>
            <g id='Group-28' transform='translate(884.000000, 821.000000)' fill='#D7D7D7'>
                <image xlinkHref={dyNuxLOZtvjoHSVisbhQ} />
            </g>
        </svg>
    ),
];

const svgBgChildArray = svgBgChild.map((item, i) => {
    const { props } = item;
    return React.cloneElement(item, { children: svgBgToParallax(props.children, i, 'vision') });
});

class Vision extends Component {
    render() {
        return (
            <div className='home-page-wrapper vision--page' id='vision'>
                <div className='vision--parallax-bg bottom' >
                    {svgBgChildArray[0]}
                </div>
                <div className='vision--parallax-bg top' >
                    {svgBgChildArray[1]}
                </div>
                <div className='page' >
                    <h2>Vision</h2>
                    <div className='title-line-wrapper vision-line'>
                        <div className='title-line' />
                    </div>
                    <ScrollOverPack component={Row} className='vision--page-content' playScale='0.4'>
                        <QueueAnim
                            component={Col}
                            componentProps={{ xs: 24, md: 12 }}
                            className='vision--page-vision'
                            key='left'
                            type='bottom'
                            leaveReverse
                        >
                            <h3 key='h1'>Unsere Vision</h3>
                            <span key='text1'>
                                Unsere Vision ist es eine Gemeinde der Liebe Jesu zu sein. Durch seine Liebe wollen wir Menschen begegnen,
                                so dass sie Gott kennen lernen, Freiheit finden, Ihre Gaben und Fähigkeiten entdecken und dann selbst einen Unterschied in dieser Welt machen.
                                Dies wollen wir erreichen in dem wir nach unseren Kulturkernwerten leben:
                                Hoffnung verkünden für einen positiven Lebenswandel
                                Gegenseitige Ehre und Wertschätzung
                                Gnade praktisch umsetzten und schnell vergeben
                                Familie prägen und offene Türen haben füreinander
                                Wachstum & Multiplikation zu unserem gemeinsamen Ziel setzen
                                Gottes Wort zu unserer Grundlage machen um Verheißung zu ergreifen
                                Ein natürliches übernatürliches Leben führen, denn für Gott ist nichts unmöglich.</span>
                        </QueueAnim>
                        <QueueAnim
                            component={Col}
                            componentProps={{ xs: 24, md: 12 }}
                            className='vision--page-faith'
                            key='right'
                            type='bottom'
                            leaveReverse
                        >
                            <h3 key='h2'>Was glauben wir</h3>
                            <span key='text2'>
                                Wir glauben an den ewigen Gott der Bibel, den Schöpfer des Himmels und der Erde, der sich uns als Vater, Sohn und Heiliger Geist offenbart.
                                Wir glauben an Gott den Vater, der in Barmherzigkeit und Fürsorge sich den Menschen zuwendet.
                                Deshalb hat er aus bedingungsloser Liebe seinen Sohn zur Errettung der Welt gegeben (Joh. 3,16). Er liebt alle Menschen und möchte, dass jeder in eine lebendige Beziehung zu Ihm findet.
                                Wir glauben, dass Jesus Christus, gezeugt vom Heiligen Geist von der Jungfrau Maria geboren wurde. Sein stellvertretender Tod am Kreuz schenkt uns die Möglichkeit, ewiges Leben zu haben, wenn wir Ihm im Glauben unser Leben übergeben.
                                Wir glauben an den Heiligen Geist, der neues Leben in uns schafft. Er ist ein wunderbarer Ratgeber, Inspirator, Tröster, Freund und Motivator.
                                Wir glauben an die göttliche Inspiration der Bibel und dass die ganze Bibel das Wort Gottes ist.
                            </span>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default Vision;


