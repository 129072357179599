import React, { Component } from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { List, Col, Row, Avatar, Button } from 'antd';
import moment, { Moment } from 'moment';
import 'moment/locale/de';

import './CalendarBanner.less';

import rrLogo from '../../../../assets/images/royal-ranger/Logo_Royal_Rangers.svg';

interface CalendarProps {
}
interface CalendarStates {
    events: { start: any; end: any; title: any; description: any}[];
    list: { start: any; end: any; title: any; description: any}[];
    limit: number;
    eventAmount: number;
    value: Moment;
}

const onDay = 'onDay';
const fullDay = 'fullDay';
const severalDays = 'severalDays';

class CalendarBanner extends Component<CalendarProps, CalendarStates> {

    constructor(props: CalendarProps) {
        super(props);
        this.state = {
            events: [],
            list: [],
            limit: 5,
            eventAmount: 0,
            value: moment(),
        };
    }

    componentDidMount = () => {
        this.getEvents(moment());
    };

    getEvents(value: moment.Moment) {
        const that = this;
        const events: { start: any; end: any; title: any; description: any }[] = [];
        let amount = 0;
        function start() {
            gapi.client.init({
                'apiKey': 'AIzaSyCDalVIvp0eZ4UNkl8B3k_ZnlNwrZbhyaM'
            }).then(function () {
                return gapi.client.request({
                    'path': `https://www.googleapis.com/calendar/v3/calendars/mbft2gss25kmtg52hus9m3taonlt01h4@import.calendar.google.com/events`,
                    'params': {
                        timeMin: moment(value).format(),
                        timeMax: moment(value).add(365, 'days').format(),
                        singleEvents: true,
                        orderBy: 'startTime',
                    }
                });
            }).then((response: any) => {
                response.result.items.map((event: any) => {
                    if (event.summary.includes('(intern)')) {

                    } else {
                        amount = amount + 1;
                        if ( amount < that.state.limit) {
                            events.push({
                                start: event.start.date || event.start.dateTime,
                                end: event.end.date || event.end.dateTime,
                                title: event.summary,
                                description: event.description,
                            });
                        }
                    }
                    return events;
                });

                that.setState({
                    events,
                    eventAmount: amount
                });
            }, function (reason: any) {
                console.log(reason);
            });
        }
        gapi.load('client', start);
    }

    getEventRange = (entry: any) => {
        if (this.getEventType(entry) === onDay) {
            return moment(entry.start).format('DD. MMMM') + ' ' + moment(entry.start).format('LT') + ' bis ' + moment(entry.end).format('LT');
        }
        else if (this.getEventType(entry) === fullDay) {
            return moment(entry.start).format('dddd DD. MMMM');
        }
        else if (this.getEventType(entry) === severalDays) {
            return moment(entry.start).format('DD. MMMM') + ' - ' + moment(entry.end).subtract(1, 'days').format('DD. MMMM YYYY');
        }
        return;
    };

    getEventType = (entry: any) => {
        if (moment(entry.start).format('MM-DD-YYYY') ===  moment(entry.end).format('MM-DD-YYYY')) {
            return onDay;
        }
        else if (moment(entry.start).format('MM-DD-YYYY') === moment(entry.end).subtract(1, 'days').format('MM-DD-YYYY')) {
            return fullDay;
        }
        else if (moment(entry.start).format('MM-DD-YYYY') < moment(entry.end).format('MM-DD-YYYY')) {
            return severalDays;
        }
        return;
    };


    getDescription = (description: String) => {
        if (description) {
            return [<InfoCircleTwoTone key={description.length} />, ' ', description];
        }
        return;
    };

    onLoadMore = () => {
        this.setState({
            limit: this.state.limit + 5
        });
        this.getEvents(moment());
    };

    render() {
        console.log(this.state.limit <= 30);
        console.log(this.state.events.length);
        console.log(this.state.limit);
        const loadMore =
        this.state.limit <= 30 && (this.state.eventAmount > this.state.limit) &&
        <div className='calendar-list__more-entry-button' >
            <Button type='primary'
                    onClick={this.onLoadMore}>Lade mehr Einträge</Button>
        </div>;

        return (
            <div className='page-wrapper calendar-list__wrapper'>
                <div className='page calendar'>
                    <div key='title' className='title-wrapper'>
                        <h1>
                            <span>
                               Veranstaltungskalender
                            </span>
                        </h1>
                        <div className='calendarBanner__title-line'>
                            <div className='title-line' />
                        </div>
                    </div>
                    <div>
                        <Row key='ul'>
                            <Col key='block0'>
                                <List
                                    loadMore={loadMore}
                                    itemLayout='horizontal'
                                    dataSource={this.state.events}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={rrLogo} />}
                                                title={item.title}
                                                description=
                                                {
                                                    <div>
                                                        <Row>{this.getEventRange(item)}</Row>
                                                    </ div>
                                                }
                                            />
                                            {this.getDescription(item.description)}
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default CalendarBanner;
