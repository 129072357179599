import React from 'react';
import { BackTop } from 'antd';
import Banner from './content/banner/Banner';
import OurTribe from './content/ourTribe/OurTribe';
import Leader from './content/leader/Leader';
import Map from './content/map/Map';
import CalendarBanner from './content/calendarBanner/CalendarBanner';
import RRGermany from './content/rrGermany/RRGermany';

import './RoyalRangers.less';


const RoyalRangers = () => (
    <div>
        <BackTop />
        <Banner/>
        <OurTribe/>
        <RRGermany />
        <Leader/>
        <CalendarBanner/>
        <Map/>
    </div>
);

export default RoyalRangers;
