import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './Info.less';

const { Text } = Typography;

interface InfoProps {
}

interface InfoStates {
    isMobile: boolean;
}

class Info extends Component<InfoProps, InfoStates> {
    render() {
        return (
            <div className='giveInfo'>
                <div className='page' >
                    <div className='giveInfo--header-wrapper'>
                        <QueueAnim className='giveInfo--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Geben</h2>
                            <div key='line' className='title-line-wrapper'>
                                <div
                                    className='title-line'
                                    style={{ transform: 'translateX(-64px)' }}
                                />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3' className='giveInfo--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>
                                    Wir glauben an das göttliche Prinzip des großzügigen Gebens.<br/>
                                    Wer großzügig sät wird auch großzügig ernten!<br/>
                                    (2.Korinther 9,6b)<br/>
                                    <br/>
                                    Als Gemeinde Gospel Center Böblingen finanzieren wir uns hauptsächlich durch freiwillige Spenden.<br/>
                                    Wir glauben, dass Gott durch uns Gemeinde bauen möchte, auch durch unsere Finanzen.<br/>
                                    Wir glauben, dass er zu seinem Wort steht und den segnet, der treu gibt,
                                    so wie es uns sein Wort in Maleachi 3,10 sagt.<br/>
                                    Dort weist er uns an, den ganzen Zehnten ins Kornhaus (die Gemeinde) zu bringen und fordert uns heraus,<br/>
                                    Ihm zu vertrauen, ob er den Geber nicht überreich segnen wird.<br/>
                                    Der Zehnte ist für uns keine Pflicht, sondern absolut freiwillig.<br/>
                                    Wir sehen ihn als ein Segensprinzip Gottes! Er möchte uns für unsere Treue segnen.<br/>
                                    Zusätzlich trägt es dazu bei, dass wir als Gemeinde unserer Berufung folgen,
                                    in dieser Welt einen Unterschied zu machen, Gemeinde zu bauen und
                                    Menschen mit dem Evangelium zu erreichen.<br/>
                                    <br/>
                                    Wenn du unsere Vision unterstützen möchtest,
                                    nutze die Möglichkeit direkt über PayPal,<br/>
                                    eine Banküberweisung oder das Einlegen einer Kollekte am Sonntag.
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default Info;
