import React, { Component } from 'react';
import { Row, Col, Tabs, Button, Typography } from 'antd';

import './Donate.less';
import { createFromIconfontCN } from '@ant-design/icons';

import paypalQrCode from '../../../../assets/images/GC_paypalQrCode.png';

const { TabPane } = Tabs;
const { Text } = Typography;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1822293_i6l4g5t42p.js',
});

interface DonateProps {
}


class Donate extends Component<DonateProps> {
    render() {
        return (
            <div className='donate-layout-wrapper donate-serve-wrapper'>
                <h2 key='h2'>So kannst Du geben</h2>
                <div className='title-line-wrapper donate-line'>
                    <div className='title-line' />
                </div>
                <div className='content-wrapper'>
                    <div className='donate-layout'>
                        <div className='donate-serve' key='donate-func'>
                            <Row key='content'>
                                <Col span={24} className='col' key='1'>
                                    <Tabs defaultActiveKey='1' type='card' key='card' size='large'>
                                        <TabPane tab={
                                                    <span>
                                                      <IconFont type='iconpaypal' />
                                                      Paypal
                                                    </span>}
                                                 key='1'
                                                 className='tabPane'
                                        >
                                            <h3 key='title'>Spende mit Paypal</h3>
                                            <Text className='block-text' key='text'>
                                                Du kannst einen einmaligen Betrag senden oder regelmäßige Spenden einrichten,<br/>
                                                indem Du dich in Dein PayPal-Konto einloggst oder Deine Kreditkartendetails eingibst.<br/>
                                                <br/>
                                                Danaach überprüfst und bestätigst Deine Spende.<br/>
                                                <br/>
                                                Am Schluss erhältst Du eine Bestätigung Deiner Spende.<br/>
                                                <br/>
                                                <br/>
                                                Klicke hier...<br /><br />
                                            </Text>
                                            <Button icon={<IconFont type='iconpaypal1' />}
                                                    type='primary'
                                                    size='large'
                                                    href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2RJYCLVDJHQKW&source=url'>
                                                Spenden
                                            </Button><br /><br />
                                            <Text className='block-text' key='text'>
                                                ...oder scanne einfach folgenden QR-Code mit deiner Paypal-App:<br />
                                            </Text>
                                            <img src={paypalQrCode} alt='Gospel Center Paypal QR-Code für Spenden' />
                                        </TabPane>
                                        <TabPane tab='Überweisung' key='2' className='tabPane'>
                                            <h3 key='title'>Spende via Überweisung <br/>
                                                oder Dauerauftrag</h3>
                                            <Text className='block-text' key='text'>
                                                <br/>
                                                Gospel Center Böblingen e.V.<br/>
                                                IBAN: DE26 6039 0000 0349 8050 08<br/>
                                                BIC: GENODES1BBV<br/>
                                                Verwendungszweck: Dein Name und Deine Anschrift</Text>
                                        </TabPane>
                                        <TabPane tab='Kollekte' key='3' className='tabPane'>
                                            <h3 key='title'>Spende via Kollekten</h3>
                                            <Text className='block-text' key='text'>
                                                <br/>
                                                In jedem Gottesdienst wird um eine Kollekte gebeten - das "Dankopfer".<br/>
                                                Wer von seinem Besitz weitergibt, <br/>
                                                bringt seine Dankbarkeit gegenüber dem Schöpfer aller Gaben zum Ausdruck.
                                            </Text>
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export default Donate;
