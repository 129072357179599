import React, { Component } from 'react';
import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { HashLink as Link } from 'react-router-hash-link';

import './StepsSelection.less';

import baptism from '../../../../assets/icons/baptism-icon.png';
import personalHelp from '../../../../assets/icons/personal-help-icon.png';
import service from '../../../../assets/icons/service-icon.png';
import church from '../../../../assets/icons/church-icon.png';

interface StepsSelectionProps {
}

interface StepsSelectionStates {
    isMobile: boolean;
}

class StepsSelection extends Component<StepsSelectionProps, StepsSelectionStates> {
    render() {
        return (
            <section className='page-wrapper steps-selection'>
                <QueueAnim
                    component={Row}
                    type='bottom'
                    className='page row text-center'
                    delay={500}
                >
                    <Col className='card-wrapper' key='1' xl={6} sm={12} xs={24}>
                        <Link smooth
                            to='#knowUs'
                        >
                            <div className='card'>
                                <h3>Uns kennen lernen</h3>
                                <img  src={church} alt='' className='card-img-top' />
                                <div className='card-body'>
                                    <span className='description text-secondary'>Besuche unseres Next-Steps-Seminar</span>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='card-wrapper' key='2' xl={6} sm={12} xs={24}>
                        <Link smooth
                              to='#baptism'
                        >
                            <div className='card'>
                                <h3>Taufe</h3>
                                <img  src={baptism} alt='' className='card-img-top' />
                                <div className='card-body'>
                                    <span className='description text-secondary'>Setze ein Zeichen, dass wir auf Grund unserer Bekehrung setzen</span>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='card-wrapper' key='3' xl={6} sm={12} xs={24}>
                        <Link smooth
                              to='#services'
                        >
                            <div className='card'>
                                <h3>Dienste</h3>
                                <img  src={service} alt='' className='card-img-top' />
                                <div className='card-body'>
                                    <span className='description text-secondary'>Besuche unseres Next-Steps-Seminar</span>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='card-wrapper' key='' xl={6} sm={12} xs={24}>
                        <Link smooth
                              to='#personalHelp'
                        >
                            <div className='card'>
                                <h3>Persönliche Hilfe</h3>
                                <img src={personalHelp} alt='' className='card-img-top' />
                                <div className='card-body'>
                                    <span className='description text-secondary'>Besuche unseres Next-Steps-Seminar</span>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </QueueAnim>
            </section>);
    }
}

export default StepsSelection;
