import React from 'react';
import Banner from './content/banner/Banner';
import History from './content/history/History';
import Vision from './content/vision/Vision';
import Pastor from './content/pastor/Pastor';
import { BackTop } from 'antd';

import './AboutUs.less';

interface AboutUsStates {
    isMobile: boolean;
}

const AboutUs = ( isMobile: AboutUsStates) => (
    <div>
        <BackTop />
        <Banner/>
        <History/>
        <Vision/>
        <Pastor/>
    </div>
);

export default AboutUs;
