import React, { Component } from 'react';

import {
    ArrowRightOutlined,
    CalendarOutlined,
    ClockCircleTwoTone,
    DownloadOutlined,
    InfoCircleTwoTone,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Calendar,
    Col,
    Row,
    Tag,
    Popover,
    Badge,
    Modal,
    Timeline,
    Button,
    Typography,
    DatePicker,
    Tooltip,
} from 'antd';
import moment, { Moment } from 'moment';
import 'moment/locale/de';

import './CalendarBanner.less';

import deDE from 'antd/lib/date-picker/locale/de_DE';

const { Paragraph, Text } = Typography;
const { MonthPicker } = DatePicker;

interface CalendarProps {
}

interface CalendarStates {
    events: { start: any; end: any; title: any; description: any}[];
    value: Moment;
    isDesktop: boolean;
    isMobile: boolean;
    isMobileSmall: boolean;
    visibleDateModal: boolean;
    visibleAboModal: boolean;
}

const onDay = 'onDay';
const fullDay = 'fullDay';
const severalDays = 'severalDays';


class CalendarBanner extends Component<CalendarProps, CalendarStates> {

    constructor(props: CalendarProps) {
        super(props);
        this.state = {
            events: [],
            value: moment(),
            isDesktop: false,
            isMobile: false,
            isMobileSmall: false,
            visibleDateModal: false,
            visibleAboModal: false,
        };
    }

    componentDidMount = () => {
        this.getEvents(moment());
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    };

    resize() {
        this.setState({isDesktop: window.innerWidth > 768});
        this.setState({isMobile: window.innerWidth <= 768 && window.innerWidth >= 330});
        this.setState({isMobileSmall: window.innerWidth < 330});
    }

    getEvents(value: Moment) {
        const that = this;
        const events: { start: any; end: any; title: any; description: any }[] = [];

        function start() {
            gapi.client.init({
                'apiKey': 'AIzaSyCDalVIvp0eZ4UNkl8B3k_ZnlNwrZbhyaM'
            }).then(function () {
                return gapi.client.request({
                    'path': `https://www.googleapis.com/calendar/v3/calendars/00im8iir0sht5hosf4otrrj3knggpqma@import.calendar.google.com/events`,
                    'params': {
                        timeMin: moment(value).startOf('month').format(),
                        timeMax: moment(value).endOf('month').format(),
                        singleEvents: true,
                        orderBy: 'startTime',
                    }
                });
            }).then((response: any) => {
                response.result.items.map((event: any) => {
                    events.push({
                        start: event.start.date || event.start.dateTime,
                        end: event.end.date || event.end.dateTime,
                        title: event.summary,
                        description: event.description,
                    });
                    return events;
                });

                that.setState({
                    events
                });
            }, function (reason: any) {
                console.log(reason);
            });
        }

        gapi.load('client', start);
    }

    getEv(date: Moment) {
        fetch('https://gc.church.tools/api/calendars/appointments/templates')
        .then(res => res.json())
        .then((data) => {
            console.log(data);
        })
        .catch(console.log);
    }

    onSelect = (value: any) => {
        const listData = this.getListData(value);

        if (listData.length !== 0) {
            this.setState({
                value,
                visibleDateModal: true,
            });
        } else {
            this.setState({
                value
            });
        }
    };

    onPanelChange = (value: any) => {
        this.setState({
            value
        });
        this.getEvents(value);
    };

    getListData = (value: any) => {
        const listData: { id: number; title: any; description: any; start: string; end: string; }[] = [];
        const listEvent = this.state.events;
        moment.locale('de');
        for (let i = 0; i < listEvent.length; i++) {
            if (
                moment(listEvent[i].start).format('MM-DD-YYYY') === moment(value).format('MM-DD-YYYY') ||
                (moment(listEvent[i].start).format('MM-DD-YYYY') < moment(value).format('MM-DD-YYYY') &&
                moment(listEvent[i].end).format('MM-DD-YYYY') > moment(value).format('MM-DD-YYYY'))
            ) {
                listData.push(
                    {
                        id: i,
                        title: listEvent[i].title,
                        description: listEvent[i].description,
                        start: listEvent[i].start,
                        end: listEvent[i].end,
                    });
            }
        }
        return listData || [];
    };

    content = (entry: { start: any; end: any; title: any; description: any}) => {
        return (
        <div>
            {entry.description &&
                <p>{this.getDescription(entry.description)}</p>
            }
            <p>{this.getEventRange(entry)}</p>
        </div>);
    };

    getDescription = (description: string) => {
        if (description) {
            return [<InfoCircleTwoTone key={description.length} />, ' ', description];
        }
        return;
    };

    getEventRange = (entry: { start: any; end: any; title: any; description: any}) => {
        if (this.getEventType(entry) === onDay) {
            return moment(entry.start).format('LT') + ' bis ' + moment(entry.end).format('LT');
        }
        else if (this.getEventType(entry) === fullDay) {
            return moment(entry.start).format('dddd DD. MMMM');
        }
        else if (this.getEventType(entry) === severalDays) {
            return moment(entry.start).format('DD. MMMM') + ' - ' + moment(entry.end).subtract(1, 'days').format('DD. MMMM YYYY');
        }
        return;
    };

    getEventType = (entry: { start: any; end: any; title: any; description: any}) => {
        if (moment(entry.start).format('MM-DD-YYYY') ===  moment(entry.end).format('MM-DD-YYYY')) {
            return onDay;
        }
        else if (moment(entry.start).format('MM-DD-YYYY') === moment(entry.end).subtract(1, 'days').format('MM-DD-YYYY')) {
            return fullDay;
        }
        else if (moment(entry.start).format('MM-DD-YYYY') < moment(entry.end).format('MM-DD-YYYY')) {
            return severalDays;
        }
        return;
    };

    getStartTime = (entry: { start: any; end: any; title: any; description: any}) => {
        if (moment(entry.start).format('MM-DD-YYYY') ===  moment(entry.end).format('MM-DD-YYYY')) {
            return moment(entry.start).format('LT');
        }
        return;
    };

    getIsLive = (entry: { start: any; end: any; title: any; description: any}) => {
        return (moment(entry.start).format('LLL') <= moment().format('LLL') &&
            moment(entry.end).format('LLL') >= moment().format('LLL'));

    };

    getProcessing = (entry: { start: any; end: any; title: any; description: any}) => {
        if (this.getIsLive(entry)) {
            return 'processing';
        }
        return;
    };

    dateCellRender = (value: any) => {
        const listData = this.getListData(value);
        const isDesktop = this.state.isDesktop;
        const isMobile = this.state.isMobile;
        const isMobileSmall = this.state.isMobileSmall;

        return (
            <div>
                {isDesktop && listData.length !== 0 &&
                    <ul className='events'>
                        {
                            listData.map((entry: { id: number; title: any; description: any; start: string; end: string; }) => (
                                <li key={entry.id}>
                                    <Popover placement='topLeft' content={this.content(entry)} title={entry.title} trigger='hover'>
                                        <div>
                                            {this.getEventType(entry) === onDay &&
                                            <Badge
                                                status={this.getProcessing(entry)}
                                                color='blue'
                                                text={[
                                                    <Text key={entry.id} strong>{this.getStartTime(entry)}</Text>
                                                    , ' ', entry.title]}
                                            />
                                            }
                                            {((this.getEventType(entry) === fullDay) || (this.getEventType(entry) === severalDays))
                                            &&
                                            <Tag color='#1890ff'><Text strong>{this.getStartTime(entry)}</Text> {entry.title}</Tag>
                                            }
                                        </div>
                                    </Popover>
                                </li>
                            ))
                        }
                    </ul>
                }
                {isMobile && listData.length !== 0 &&
                    <Badge className='float-right'  style={{ backgroundColor: '#1890ff' }} count={listData.length} />
                }
                {isMobileSmall && listData.length !== 0 &&
                    <Badge color='blue'/>
                }
            </div>
        );
    };


    handleOk = () => {
        this.setState({
            visibleDateModal: false,
            visibleAboModal: false
        });
    };

    handleCancel = () => {
        this.setState({
            visibleDateModal: false,
            visibleAboModal: false
        });
    };

    handleAbo  = () => {
        this.setState({
            visibleDateModal: false,
            visibleAboModal: true
        });
    };

    render() {
        const { value } = this.state;
        const listData = this.getListData(value);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18},
            },
        };

        return (
            <div className='home-page-wrapper calendar-wrapper'>
                <div className='home-page calendar'>
                    <div key='title' className='title-wrapper'>
                        <h1>
                            <span>
                                Veranstaltungskalender
                            </span>
                        </h1>
                    </div>
                    <div className='content-template '>
                        <Row key='ul' className='calendar-img-wrapper'>
                            <Col key='block0' className='block'>
                                <Modal
                                    title={`${moment(value).format('dddd DD. MMMM')}`}
                                    visible={this.state.visibleDateModal}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        <Button key='Ok' type='primary' onClick={this.handleOk}>
                                            Schließen
                                        </Button>,
                                    ]}
                                >
                                    <Timeline>
                                        {listData.map((entry: any) => (
                                            <Timeline.Item
                                                dot=
                                            {((this.getEventType(entry) === fullDay) || (this.getEventType(entry) === severalDays))
                                                &&
                                                <ClockCircleTwoTone />
                                            }
                                                key={entry.id}>
                                                {[<Text key={entry.id} strong>{this.getEventRange(entry)}</Text>, ' ',  entry.title]}
                                            </Timeline.Item>
                                        ))}
                                    </Timeline>
                                </Modal>
                                <Modal
                                    title={[<CalendarOutlined />, '  Abonnieren']}
                                    visible={this.state.visibleAboModal}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        <Button key='Ok' type='primary' onClick={this.handleOk}>
                                            Schließen
                                        </Button>,
                                    ]}
                                >
                                    <Form {...formItemLayout}>
                                        <Form.Item label='Ausführen'>
                                            <Row>
                                                <Col>
                                                    <Button icon={<DownloadOutlined />} type='link' target='_clean' href='https://gc.church.tools/?q=churchcal/ical'>iCal-URL</Button>
                                                    <Button icon={<ArrowRightOutlined />} type='link' target='_clean' href='webcal://gc.church.tools/?q=churchcal/ical'>WebCal-URL</Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item label={
                                            <span>
                                                Link&nbsp;
                                                <Tooltip title='Um den Kalender zu abonnieren die Adresse anbei in einen beliebigen Kalender importieren, der iCal unterstützt.'>
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </span>
                                        } extra={'Um den Kalender zu abonnieren die Adresse anbei in einen beliebigen Kalender importieren, der iCal unterstützt.'}>
                                            <Row>
                                                <Col>
                                                    <Paragraph copyable>https://gc.church.tools/?q=churchcal/ical</Paragraph>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                                <Calendar
                                    // @ts-ignore
                                    value={value}
                                    onSelect={this.onSelect}
                                    onPanelChange={this.onPanelChange}
                                    dateCellRender={this.dateCellRender}
                                    className='block gc-calendar'
                                    mode='month'
                                    headerRender={({ value, onChange }) => {
                                        moment.locale('');
                                        // @ts-ignore
                                        const month = moment(value);
                                        return (
                                            <div className='ant-fullcalendar-header'>
                                                <MonthPicker
                                                    className='monthPicker'
                                                    locale={deDE}
                                                    allowClear={false}
                                                    value={month}
                                                    format='MMM YYYY'
                                                    onChange={selectedMonth => {
                                                        // @ts-ignore
                                                        onChange(selectedMonth);
                                                    }}
                                                />
                                                <Button
                                                    icon={<CalendarOutlined />}
                                                    className='iCalButton'
                                                    onClick={this.handleAbo}
                                                    block={this.state.isMobileSmall}
                                                >
                                                    Abonnieren
                                                </Button>
                                            </div>
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default CalendarBanner;
