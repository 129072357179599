import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import './NoProfit.less';
import QueueAnim from 'rc-queue-anim';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';

const { Text } = Typography;

interface NoProfitProps {
}

interface NoProfitStates {
}

class NoProfit extends Component<NoProfitProps, NoProfitStates> {

    constructor(props: NoProfitProps) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    render() {
        return (
            <div className='noProfit'>
                <div className='page' >
                    <div className='noProfit--header-wrapper'>
                        <QueueAnim className='noProfit--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Gemeinnützigkeit</h2>
                            <div key='line' className='title-line-wrapper'>
                                <div
                                    className='title-line'
                                    style={{ transform: 'translateX(-64px)' }}
                                />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3' className='noProfit--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>
                                    Das Gospel Center Böblingen ist ein gemeinnützig und mildtätig anerkannter Verein<br/>
                                    und stellt zum Beginn eines neuen Jahres eine Zuwendungsbestätigung für Spenden aus.<br/>
                                    Diese kann in der Steuererklärung geltend gemacht werden.<br/>
                                    Bitte gib bei deiner Spende einfach deinen Namen und deine Anschrift an.
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </div>
        );
    }
}

export default NoProfit;
