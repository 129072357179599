import React, { Component } from 'react';
import { Col, Row, Typography, Divider, Button, Avatar } from 'antd';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import { YoutubeFilled } from '@ant-design/icons';

import './GlobalFooter.less';

import logo from '../../assets/images/GC_lettering_white.png';
import churchToolsIcon from '../../assets/icons/churchtools.png';

import LegalModal from './legalModal/LegalModal';

const { Title, Text } = Typography;

interface FooterProps {
}

interface FooterStates {
    visibleLegalModal: boolean;
    activeTab: string;
}

class GlobalFooter extends Component<FooterProps, FooterStates> {

    constructor(props: FooterProps) {
        super(props);
        this.state = {
            visibleLegalModal: false,
            activeTab: '1',
        };
    }

    handleLegal = (tab: string) => {
        this.setState({
            visibleLegalModal: true,
            activeTab: tab
        });
    };

    render() {
        const { visibleLegalModal, activeTab } = this.state;
        return (
            <div className='home-page-wrapper footer-wrapper'>
                <LegalModal activeTab={activeTab} visibleLegalModal={visibleLegalModal}/>
                <OverPack className='footer' playScale={0.2}>
                    <QueueAnim
                        type='bottom'
                        key='ul'
                        leaveReverse
                        component={Row}
                        {...{className: 'home-page'}}
                    >
                        <Col key='1' className='block ant-col-xs-24 ant-col-md-8 margin-bottom'>
                            <Title level={3} className='white'>Spendenkonto</Title>
                            <h3 className='logo'>
                                <img src={logo} width='100%' alt='img' />
                            </h3>
                            <Text className='white'>
                                IBAN: DE26 6039 0000 0349 8050 08<br/>
                                BIC: GENODES1BBV<br/>
                            </Text>

                        </Col>
                        <Col key='2' className='block ant-col-xs-24 ant-col-md-8 margin-bottom'>
                            <Title level={3} className='white'>Adresse</Title>
                            <div>
                                <Text className='white'>
                                    Sindelfinger Str. 35<br/>
                                    71032 Böblingen<br/>
                                    Tel: 07031 - 412106<br/>
                                    Fax: 07031 - 417006<br/>
                                    Mail: <a href='mailto:info@gospelcenter.de'>info@gospelcenter.de</a>
                                </Text>
                            </div>
                        </Col>
                        <Col key='3' className='block ant-col-xs-24 ant-col-md-8'>
                            <Title level={3} className='white'>Bürozeiten</Title>
                            <div className='fixWidth'>
                                <Text className='white'>Dienstag:</Text>
                                <Text className='white float-right'>09:30 bis 12:30 Uhr</Text><br/>
                                <Text className='white'>Mittwoch:</Text>
                                <Text className='white float-right'>09:30 bis 12:30 Uhr</Text><br/>
                                <Text className='white float-right'>14:00 bis 15:30 Uhr</Text><br/>
                                <Text className='white'>Freitag:</Text>
                                <Text className='white float-right'>09:30 bis 12:30 Uhr</Text><br/>
                            </div>
                        </Col>
                    </QueueAnim>
                    <TweenOne
                        animation={{ y: '+=30', opacity: 0, type: 'from' }}
                        key='social-media'
                        className='social-media-wrapper'>
                        <div className='social-media-wrapper-box'>
                            <div className='white social-media'>
                                <a target='_blank'
                                   rel='noopener noreferrer'
                                   aria-label='Open Gospel Center Church Tools page'
                                   href='https://gc.church.tools'
                                   className='social-media-button'>
                                    <Avatar
                                        className='churchtools'
                                        src={churchToolsIcon}
                                        size={52}
                                    />
                                </a>
                                <a target='_blank'
                                   rel='noopener noreferrer'
                                   aria-label='Open Gospel Center Church Youtube channel'
                                   href='https://www.youtube.com/channel/UCGMhlGs4i5jrA4hc5SuLQkw'
                                   className='social-media-button'>
                                    <Avatar
                                        className='youtube'
                                        icon={<YoutubeFilled />}
                                        size={52}
                                    />
                                </a>
                            </div>
                        </div>
                    </TweenOne>
                    <TweenOne
                        animation={{ y: '+=30', opacity: 0, type: 'from' }}
                        key='copyright'
                        className='copyright-wrapper'
                    >
                        <div className='copyright-wrapper-box'>
                            <div className='white copyright'>
                                <Text className='white'>©2019 Gospel Center Böblingen e.V.</Text>
                                <Divider type='vertical' />
                                <Button
                                    type='link'
                                    className='white-button no-padding'
                                    onClick={() => this.handleLegal('1')}>
                                    Impressum
                                </Button>
                                <Divider type='vertical' />
                                <Button
                                    type='link'
                                    className='white-button no-padding'
                                    onClick={() => this.handleLegal('2')}>
                                    Datenschutz
                                </Button>
                                <Divider type='vertical' />
                                <Button
                                    type='link'
                                    className='white-button no-padding'
                                    href='https://gospelcenter.atlassian.net/servicedesk/customer/portals'
                                    target='_blank'>
                                    Hilfe & Support
                                </Button>
                            </div>
                        </div>
                    </TweenOne>
                </OverPack>
            </div>
        );
    }
}

export default GlobalFooter;
