import React, { Component } from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import Parallax from 'rc-scroll-anim/lib/ScrollParallax';
import QueueAnim from 'rc-queue-anim';
import { Modal } from 'antd';

import './ChristianService.less';

import kids from '../../../../assets/icons/Service/kids.svg';
import music from '../../../../assets/icons/Service/music.svg';
import coffee from '../../../../assets/icons/Service/coffee.svg';
import smallGroups from '../../../../assets/icons/Service/smallgroups.svg';
import welcome from '../../../../assets/icons/Service/welcome.svg';
import tech from '../../../../assets/icons/Service/tech.svg';
import healing from '../../../../assets/icons/Service/healing.svg';
import tbs from '../../../../assets/icons/Service/tbs.svg';
import pray from '../../../../assets/icons/Service/pray.svg';
import youth from '../../../../assets/icons/Service/youth.svg';
import order from '../../../../assets/icons/Service/order.svg';
import caretaker from '../../../../assets/icons/Service/caretaker.svg';


const { info } = Modal;

interface BaptismProps {
}

interface BaptismStates {
    isMobile: boolean;
    hoverNum: any;
}

const featuresCN = [
    {
        title: 'Technik',
        src: tech,
        color: '#13C2C2',
        shadowColor: 'rgba(19,194,194,.12)',
        maxHeight: '32px',
        description: 'Du bist begeistert von Technik und Musik? Du hast ein gutes Gehör oder Freude daran mit Computern zu arbeiten, Tonaufnahmen oder Liedtexte zu Beamen? Dann bist du hier genau richtig!'
    },
    {
        title: 'Welcome Team',
        src: welcome,
        color: '#2F54EB',
        shadowColor: 'rgba(47,84,235,.12)',
        maxHeight: '37px',
        description: 'Du gehst gerne auf Menschen zu und liebst es neue Leute kennen zu lernen und ihnen zu helfen sich zu orientieren? Dann ist das Welcome Team genau der richtige Platz für dich!'
    },
    {
        title: 'Ordner',
        src: order,
        color: '#F5222D',
        shadowColor: 'rgba(245,34,45,.12)',
        maxHeight: '30px',
        description: 'Dir liegt Sicherheit am Herzen? Du hast einen guten Überblick und bist bereit beim Einsammeln des Opfers zu helfen wie auch Menschen sich im Gebäude zurecht zu finden? Dann ist der Ordnerdienst genau der richtige Bereich für dich!'
    },
    {
        title: 'Musik',
        src: music,
        color: '#1AC44D',
        shadowColor: 'rgba(26,196,77,.12)',
        maxHeight: '28px',
        description: 'Du bist begeisterte (r) Musiker (in) oder Sänger (in)? Du liebst es Gott anzubeten und Menschen in den die Anbetung zu führen? Dann ist der Lobpreisbereich genau richtig für dich!'
    },
    {
        title: 'TBS',
        src: tbs,
        color: '#FAAD14',
        shadowColor: 'rgba(250,173,20,.12)',
        maxHeight: '35px',
        description: 'Du hast ein Herz für junge Menschen? Du hast eine Gabe Glaubensinhalte altersgerecht weiterzugeben und so ein Fundament in dem Leben der Teenager zu schaffen? Dann bist du genau richtig ein Lehrer der Teeni-Bibel-Schule zu sein/werden!'
    },
    {
        title: 'Kinderdienst',
        src: kids,
        color: '#722ED1',
        shadowColor: 'rgba(114,46,209,.12)',
        maxHeight: '32px',
        description: [
                <br/>,
                <strong>Kindergottesdienst:</strong>, <br/>,
                'Kinder sind ein wertvolles Geschenk Gottes. Du hast es auf dem Herzen den kleinsten zu dienen und ihnen die Liebe Jesu weiterzugeben? Dann bist du hier genau richtig!',
                <br/>, <br/>,
                <strong>Kidsclub:</strong>, <br/>,
                'Du möchtest den Kindern durch Spiel und Spaß, das Evangelium weiter geben und ihnen Liebe und Wertschätzung ausdrücken? Dann bist du hier genau richtig!'
            ]
    },
    {
        title: 'Gebet',
        src: pray,
        color: '#FA8C16',
        shadowColor: 'rgba(250,140,22,.12)',
        maxHeight: '32px',
        description: 'Mit Gebet steht und fällt die Gemeinde Jesu. Du bist ein Gebetskämpfer und möchtest den Menschen und der Gemeinde darin dienen? Dann bist du hier genau richtig!'
    },
    {
        title: 'Kaffeebar',
        src: coffee,
        color: '#EB2F96',
        shadowColor: 'rgba(235,45,150,.12)',
        maxHeight: '31px',
        description: 'Du liebst es Menschen zu dienen? Ihnen die Möglichkeit zu schaffen nach dem Gottesdienst noch eine gemütliche Gemeinschaft bei einem Kaffee und Kuchen zu ermöglichen? Dann bist du hier genau richtig!'
    },
    {
        title: 'Hausmeisterdienst',
        src: caretaker,
        color: '#1890FF',
        shadowColor: 'rgba(24,144,255,.12)',
        maxHeight: '39px',
        description: 'Du bist Handwerklich begabt und liebst Heimwerkerarbeiten oder Gartenarbeit? Dann ist das genau dein Bereich!'
    },
    {
        title: 'Kleingruppenleiter',
        src: smallGroups,
        color: '#13C2C2',
        shadowColor: 'rgba(19,194,194,.12)',
        maxHeight: '32px',
        description: 'Du liebst den Tiefgang und das Investment in Menschen? Du öffnest gerne deine Tür und lebst Gemeinschaft? Du hast ein Hobby, das du mit anderen Teilst und nutzen möchtest um das Evangelium weiter zugeben? Dann bist du gesucht um einen Unterschied zu machen!'
    },
    {
        title: 'Healingrooms',
        src: healing,
        color: '#2F54EB',
        shadowColor: 'rgba(47,84,235,.12)',
        maxHeight: '37px',
        description: 'Wir als Gemeinde glauben an die Göttliche Heilung. Du auch? Wenn du eine Gabe in diesem Bereich hast und es dir ein Herzensanliegen ist das Menschen Heilung erfahren, sowohl Körperlich wie auch seelisch? Dann bist du hier genau richtig!'
    },
    {
        title: 'Jugend',
        src: youth,
        color: '#F5222D',
        shadowColor: 'rgba(245,34,45,.12)',
        maxHeight: '37px',
        description: [
            <br/>,
            <strong>Teens:</strong>, <br/>,
            'Du hast es auf dem Herzen jugendlichen Menschen zu helfen den richtigen Weg zu finden? Sie zu begleiten die beste Entscheidung ihres Lebens zu treffen, ein Leben mit Jesus? Dann bist du hier genau richtig?',
            <br/>, <br/>,
            <strong>Royal Ranger:</strong>, <br/>,
            'Du bist ein Mensch der die Natur liebt? Der für Pfadfindertechnisches begeisterungsfähig ist und das nutzen möchte um Kindern und Jugendlichen mit dem Evangelium zu begegnen? Dann bist du hier genau richtig?'
        ]
    },
];

/*const pointPos = [
    { x: -30, y: -10 },
    { x: 20, y: -20 },
    { x: -65, y: 15 },
    { x: -45, y: 80 },
    { x: 35, y: 5 },
    { x: 50, y: 50, opacity: 0.2 },
];*/

class ChristianService extends Component<BaptismProps, BaptismStates> {
    constructor(props: BaptismProps) {
        super(props);
        this.state = {
            isMobile: false,
            hoverNum: null,
        };
    }
    componentDidMount = () => {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    };

    resize() {
        this.setState({isMobile: window.innerWidth < 768});
    }

    onMouseOver = (i: any) => {
        this.setState({
            hoverNum: i,
        });
    };

    onMouseOut = () => {
        this.setState({
            hoverNum: null,
        });
    };

    handleModal = (item: any) => {
        info({
            title: item.title,
            content: item.description,
            centered: true,
            icon: <img src={item.src} alt='img' className='modalIcon'/>,
            okText: 'Interessant',
            maskClosable: true
        });
    };

    getEnter = (e: { index: string | number; }) => {
        //const i = e.index;
        const r = (Math.random() * 2) - 1;
        const y = (Math.random() * 10) + 5;
        //const delay = Math.round(Math.random() * (Number(i.toString()) * 50));
        return [
            /*{
                delay, opacity: 0.4, ...pointPos[e.index], ease: 'easeOutBack', duration: 300,
            },*/
            {
                y: r > 0 ? `+=${y}` : `-=${y}`,
                duration: (Math.random() * 1000) + 2000,
                yoyo: true,
                repeat: -1,
            }];
    };

    render() {
        let children = [[], [], [], [], [], [], [], [], [], [], [], []];
        featuresCN.forEach((item, i) => {
            //const isHover = this.state.hoverNum === i;
            const child = (
                <li key={i.toString()} >
                    <div
                        className='service-box'
                        onMouseEnter={() => { this.onMouseOver(i); }}
                        onMouseLeave={this.onMouseOut}
                        onClick={() => this.handleModal(item)}
                    >
                        <div className='service-image'>
                            <img src={item.src} alt='img'/>
                        </div>
                        <h3>{item.title}</h3>
                    </div>
                </li>
            );

            if (this.state.isMobile) {
                // @ts-ignore
                children[i].push(child);
            }
            else {
                // @ts-ignore
                children[Math.floor(i / 3)].push(child);
            }
        });


        // @ts-ignore
        children = children.map((item, i) => (
            <QueueAnim
                className='service-box-wrapper'
                key={i.toString()}
                type='bottom'
                leaveReverse
                delay={[i * 100, (children.length - 1 - i) * 100]}
                component='ul'
            >
                {item}
            </QueueAnim>
        ));
        return (
            <section id='services' className='service-page service' >
                <div className='service-page-wrapper' id='service-wrapper'>
                    {!this.state.isMobile && (
                        <Parallax
                            className='service-bg'
                            animation={{ translateY: 200, ease: 'linear', playScale: [0, 1.65] }}
                            location='service-wrapper'
                        >
                            Dienste
                        </Parallax>
                    )}
                    <h2>Dienste</h2>
                    <div className='title-line-wrapper service-line'>
                        <div className='title-line' />
                    </div>
                    <OverPack>
                        {children}
                    </OverPack>
                </div>
            </section>
        );
    }
}

export default ChristianService;
