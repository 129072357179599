import React from 'react';
import Content from './content/Content';

import './Coronavirus.less';


const Coronavirus = () => (
    <div>
        <Content />
    </div>
);

export default Coronavirus;
