import React from 'react';
import { Result, Button } from 'antd';

import './Page404.less';


const Page404 = () => (
    <div>
        <Result
            status='404'
            title='404'
            subTitle='Die von Ihnen besuchte Seite existiert leider nicht.'
            extra={<Button type='primary' href='/'>Zurück zur Homepage</Button>}
        />,
    </div>
);

export default Page404;
