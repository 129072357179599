import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col, Button } from 'antd';

import './BooksTableOnline.less';

interface BooksTableOnlineProps {
}


class BooksTableOnline extends Component<BooksTableOnlineProps> {
    render() {
        return (
        <div className='booksTableOnline-layout-wrapper booksTableOnline-serve-wrapper'>
            <h2 key='h2'>Büchertisch online</h2>
            <div className='title-line-wrapper booksTableOnline-line'>
                <div className='title-line' />
            </div>
            <div className='content-wrapper'>
                <OverPack className='booksTableOnline-layout' playScale={0.4}>
                    <QueueAnim className='booksTableOnline-serve' type='bottom' key='booksTableOnline-func' ease='easeOutQuart' leaveReverse>
                        <Row key='content'>
                            <Col span={24} className='col' key='1'>
                                <QueueAnim
                                    type='bottom'
                                    className='content-wrapper'>
                                    <p key='1'>Der Online-Büchertisch ist rund um die Uhr für Sie da!<br/>
                                        Umfangreiche Auswahl, sowie schnelle Lieferung sind damit gewährleistet.<br/>
                                        <br/>
                                        <strong>Durch Ihren Einkauf an unserem Büchertisch oder Büchertisch-online unterstützen<br/>
                                            Sie die Arbeit unserer Gemeinde.</strong>
                                    </p>
                                    <div key='2'>
                                        <Button
                                            className='scm-button'
                                            size={'large'}
                                            type={'primary'}
                                            onClick={() => { window.open('http://www.scm-shop.de?pa=9901146', '_blank'); }}>
                                            <strong>SCM </strong> shop.de
                                        </Button>
                                    </div>
                                    <p key='3'>
                                        Wenn Sie über unseren Online-Büchertisch einkaufen, erhalten Sie Ihre Bestellung per Post zugeschickt.<br/>
                                        Online-Bestellungen, die ein gedrucktes Buch enthalten, liefern wir versandkostenfrei!<br/>
                                        Dies gilt für Bestellungen von Privatkunden ab einem gesamten Bestellwert von 12,-  €.<br/>
                                        Bei Bestellungen, die kein Buch enthalten fallen bis zu einem Bestellwert von 29 € Versandkosten in Höhe von 3,95 € an.<br/>
                                        Bestellungen ab 29 € liefern wir innerhalb Deutschlands versandkostenfrei aus.<br/>
                                        Sie müssen sie nicht am Büchertisch in der Gemeinde abholen.<br/>
                                        <br/>
                                        Es gelten die AGB's und Lieferbedingungen des SCM Shops.<br/>
                                        Wir freuen uns auf Ihren Einkauf – offline und auch online!<br/>
                                    </p>
                                </QueueAnim>
                            </Col>
                        </Row>
                    </QueueAnim>
                </OverPack>
            </div>
        </div>);
    }
}

export default BooksTableOnline;
