import React, { Component } from 'react';
import {  Menu } from 'antd';
import TweenOne from 'rc-tween-one';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import './GlobalHeader.less';
import logo from '../../assets/images/GC.png';

import { YoutubeFilled } from '@ant-design/icons';

interface HeaderProps extends RouteComponentProps {
}
interface HeaderStates {
    phoneOpen: boolean;
    menuHeight: number;
    isMobile: boolean;
    current: string;
}

class GlobalHeader extends Component<HeaderProps, HeaderStates> {

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            phoneOpen: false,
            menuHeight: 0,
            isMobile: false,
            current: 'home',
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({isMobile: window.innerWidth <= 1024});
    }

    phoneClick = () => {
        const phoneOpen = !this.state.phoneOpen;
        this.setState({
            phoneOpen,
        });
    };

    handleClick = () => {
        const phoneOpen = false;
        this.setState({
            phoneOpen,
            menuHeight: 0,
        });
    };

    render() {
        const { menuHeight } = this.state;
        const { location } = this.props;

        return (
            <TweenOne
                component='header'
                animation={{ opacity: 0, type: 'from' }}
                {...{ className: 'globalHeader home-page-wrapper' }}

            >
                <div className={`home-page${this.state.phoneOpen ? ' open' : ''}`}>
                    <TweenOne
                        animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
                        className='globalHeader-logo'
                    >
                        <Link to='/'
                              onClick={() => {
                                this.handleClick();
                              }}
                        >
                            <img src={logo} alt='logo' />
                            <span>Gospel Center</span>
                        </Link>
                    </TweenOne>
                    {this.state.isMobile && (
                        <div
                            className='globalHeader-mobile-menu'
                            onClick={() => {
                                this.phoneClick();
                            }}
                        >
                            <em />
                            <em />
                            <em />
                        </div>
                    )}
                    <TweenOne
                        className='globalHeader-menu'
                        animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
                        style={this.state.isMobile ? { height: menuHeight } : undefined}
                        //ref={(ref) => this.menu = ref}

                    >
                        <Menu mode={this.state.isMobile ? 'inline' : 'horizontal'}
                              key='menu' selectedKeys={[location.pathname]}
                              onClick={() => {
                                this.handleClick();
                            }}
                        >
                            <Menu.Item key='/aboutus' >
                                <NavLink to='/aboutus'>
                                    Über uns
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key='/nextsteps'>
                                <NavLink to='/nextsteps'>
                                    Next Steps
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key='/royalrangers'>
                                <NavLink to='/royalrangers'>
                                    Royal Rangers
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key='/shop'>
                                <NavLink to='/shop'>
                                    Shop
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key='geben'>
                                <NavLink to='/give'>
                                    Geben
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key='/livestream'>
                                <a href='https://www.youtube.com/channel/UCGMhlGs4i5jrA4hc5SuLQkw'>
                                    <YoutubeFilled style={{color: '#ff0000'}}/>Livestream und Predigten
                                </a>
                            </Menu.Item>
                        </Menu>
                    </TweenOne>
                </div>
            </TweenOne>
        );
    }
}

export default withRouter(GlobalHeader);
