import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './Baptism.less';

const { Text } = Typography;

interface BaptismProps {
}

interface BaptismStates {
    isMobile: boolean;
}

class Baptism extends Component<BaptismProps, BaptismStates> {
    render() {
        return (
            <section id='baptism' className='page-wrapper baptism'>
                <div className='page' >
                    <div className='baptism--header-wrapper'>
                        <QueueAnim className='baptism--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Taufe</h2>
                            <div key='line' className='title-line-wrapper'>
                                <div
                                    className='title-line'
                                    style={{ transform: 'translateX(-64px)' }}
                                />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack className='baptism--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>
                                    <strong>Warum Taufe?</strong><br />
                                    <br />
                                    Die Taufe ist ein Zeichen vor der sichtbaren und unsichtbaren Welt, mit dem ich mich zu Jesus Christus als meinem Herrn bekenne.
                                    In Apostelgeschichte 2, 38 ruft Petrus die Volksmenge zur Umkehr auf, die durch die Taufe ausgedrückt wird.
                                    Jesus selbst ist uns ein Vorbild, indem auch er sich taufen ließ.<br />
                                    Wie Paulus schreibt, sind wir in den Tod Jesu hineingetauft und auch mit ihm wieder auferstanden (Römer 6, 3-4).
                                    Somit ist die Taufe ein Zeichen dafür, dass wir nun einen neuen Lebenswandel haben und nicht mehr unserem Willen folgen,
                                    sondern dem Willen Jesu (Galater 2, 20).<br />
                                    Die Taufe ist also ein Zeichen, dass wir auf Grund unserer Bekehrung setzen, um klar unsere Zugehörigkeit zu Jesus zu bekennen.
                                    Sie ist für uns als Gemeinde ein Folgeschritt der Bekehrung.
                                    Außerdem ist sie ein Schritt aus Gehorsam gegenüber Gott, da Jesus uns in Matthäus 28, 19-20 den Auftrag gibt, hinauszugehen,
                                    Menschen zu Jüngern zu machen und sie auf den Namen des Vaters, des Sohnes und des Heiligen Geistes zu taufen.<br />
                                    <br />
                                </Text>
                            </Col>
                            <Col
                                key='2'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>
                                    <strong>Wie sieht Taufe bei uns aus:</strong><br />
                                    <br />
                                    Wir als Gospel Center praktizieren die Glaubenstaufe. Dabei hat der Täufling die Entscheidung zur Taufe getroffen.
                                    Bei der Taufe werden die Täuflinge untergetaucht. Dadurch wird das Wort aus Römer 6, 3-4 erfüllt,
                                    der Täufling wird in den Tod Jesu getauft und steht zu neuem Leben auf.
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </section>
        );
    }
}

export default Baptism;
