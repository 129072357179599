import React, { Component } from 'react';
import { Typography } from 'antd';

import './Disclaimer.less';

const { Title, Text } = Typography;


class Disclaimer extends Component {

    render() {

        return (
            <div className='disclaimer'>

                <Title level={3}>Haftungsausschluss / Disclaimer</Title>
                <Text className='impressum__margin-bottom'>Der Gospel Center Böblingen e.V. (GCBB e.V.) übernimmt keinerlei Gewähr für die Aktualität, Korrektheit oder
                    Vollständigkeit der bereitgestellten Informationen. Haftungsansprüche gegen den GCBB e.V., die durch die Nutzung
                    der bereitgestellten Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht
                    wurden, sind grundsätzlich ausgeschlossen, sofern seitens des GCBB e.V. kein vorsätzliches oder grob fahrlässiges
                    Verschulden vorliegt. Der GCBB e.V. behält es sich vor, Portalinhalte ohne gesonderte Ankündigung zu verändern, zu
                    ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</Text>

                <Title level={3}>Haftung für Inhalte</Title>
                <Text className='impressum__margin-bottom'>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</Text>


                <Title level={3}>Haftung für Links</Title>
                <Text className='impressum__margin-bottom'>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</Text>


                <Title level={3}>Urheberrecht</Title>
                <Text className='impressum__margin-bottom'>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </Text>

                <Title level={3}>Keine Abmahnung ohne vorherigen Kontakt!</Title>
                <Text className='impressum__margin-bottom'>Sollte der Inhalt, Aufmachung oder Bilder dieser Seiten Rechte Dritter oder gesetzliche Bestimmungen verletzen, so bitten wir um eine entsprechende Nachricht. Wir garantieren, dass die zu Recht beanstandeten Passagen oder Bilder unverzüglich entfernt werden. Falls Sie dazu die Abgabe einer strafbewehrten Unterlassungserklärung fordern und diese berechtigt ist, werden wir diese selbstverständlich abgeben. Dazu ist die Einschaltung eines Rechtsbeistandes nicht notwendig. Diesbezügliche Kosten werden wir Ihnen nicht erstatten.
                </Text>

                <Title level={3}>Streitschlichtung</Title>
                <Text className='impressum__margin-bottom'>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </Text>
            </div>
        );
    }
}

export default Disclaimer;
