import React from 'react';
import Banner from './content/banner/Banner';
import Map from './content/googleMaps/Map';
import CalendarBanner from './content/calendar/CalendarBanner';
import Welcome from './content/welcome/Welcome';
import Worship from './content/worship/Worship';
import SmallGroups from './content/smallGroups/SmallGroups';
//import CurrentEvents from './content/currentEvents/CurrentEvents'
import { BackTop } from 'antd';

import './LandingPage.less';


const LandingPage = () => (
    <div>
        <BackTop />
        <Banner />
        <Welcome />
        <Worship />
        <SmallGroups />
        <CalendarBanner />
        <Map />
    </div>
);

export default LandingPage;
