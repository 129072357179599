import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Col, Row, Typography } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import './SmallGroups.less';

const { Title, Text } = Typography;

class SmallGroups extends Component {

    render() {
        return (
            <div  className='home-page-wrapper small-groups-wrapper'>
                <div className='home-page small-groups'>
                    <OverPack  playScale={0.2}>
                        <QueueAnim
                            type='bottom'
                            key='block'
                            leaveReverse
                            component={Row}
                            {...{ className: 'block-wrapper' }}
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Title level={3} className='small-groups-title'>Kleingruppen</Title>
                                <Text className='block-text'>
                                    Miteinander in Beziehungen leben und Veränderung erfahren – das geschieht in unseren Kleingruppen.<br/>
                                    Wenn du Gemeinschaft möchtest oder im Glauben wachsen willst, dann bist du in einer Kleingruppe genau richtig.<br/>
                                    Es gibt ein vielfältiges Angebot an Gruppen, die sich an verschiedenen Orten treffen. Wir sind sicher, dass es auch für dich die passende Kleingruppe gibt.<br/>
                                    Um mehr über das Kleingruppenangebot zu erfahren, schau in den Kleingruppen-Flyer.<br/>
                                    Sende dazu eine E-Mail an: <a href='mailto:info@gospelcenter.de'>info@gospelcenter.de</a>
                                </Text>
                            </Col>
                        </QueueAnim>
                    </OverPack>
                </div>
            </div>
        );
    }
}

export default SmallGroups;
