import React, { Component } from 'react';
import { Typography } from 'antd';
import { YoutubeFilled } from '@ant-design/icons';

import './Content.less';

const { Title, Text } = Typography;




class Content extends Component {

    render() {

        return (
            <div className='coronavirus__wrapper'>
                <Title level={2} className='coronavirus-title'>Informationen zum Coronavirus</Title>
                
                <Title level={3}>Wir informieren über die wichtigsten Fragen und Antworten.</Title>
                <Text className='coronavirus__margin-bottom'>
                    Wir dürfen als Gemeinde Gospel Center Böblingen unter Maßgaben des Infektionsschutzgesetzes Gottesdienste und Gebetsveranstaltungen abhalten.<br />
                    Aktuell gilt <b>keine Begrenzung</b> der maximalen Besucherzahl und auch <b>keine Anmeldepflicht</b> sowie <b>keine Maskenpflicht</b> mehr!<br />
                    <br />
                    Vorort stehen genügend freie Plätze unter Einhaltung des Infektionsschutzes bereit.<br />
                    <b>Komm gerne vorbei und sei herzlich Willkommen!</b> 
                </Text>

                <Title level={3} className='content-title'>Kindergottesdienst</Title>
                <Text className='coronavirus__margin-bottom'>
                   Auch die Kindergottesdienste finden parallel zum Gottesdienst statt.<br />
                </Text>

                <Title level={3} className='content-title'>Livestream</Title>
                <Text className='coronavirus__margin-bottom'>
                    Weiterhin wird der Gottesdienst auch per <a href='https://www.youtube.com/channel/UCGMhlGs4i5jrA4hc5SuLQkw'> Livestream <YoutubeFilled style={{color: '#ff0000'}}/></a> verfügbar sein.<br />
                </Text>

                <Title level={3} className='content-title'>Allgemeine Informationen zum Corona-Virus</Title>
                <Text>
                    Eine detailierte Zusammenstellung mit allen Informationen erhalten Sie auf </Text><br/>
                    <a href='https://www.boeblingen.de/corona-virus' target='_blank' rel='noopener noreferrer'>
                        Allgemeine Informationen zum Corona-Virus Böblingen
                    </a>
                <br/>
                <a href='https://km-bw.de/,Lde/Startseite/Ablage+Einzelseiten+gemischte+Themen/Religioese+Angelegenheiten'
                   target='_blank'
                   rel='noopener noreferrer'>
                Ausführungs-Verordnung des Kultusministeriums zu Maßnahmen im Gottesdienst
                </a>
                <br/>
                <a href='https://www.baden-wuerttemberg.de/de/service/aktuelle-infos-zu-corona/aktuelle-corona-verordnung-des-landes-baden-wuerttemberg/'
                   target='_blank'
                   rel='noopener noreferrer'>
                Aktuelle Corona-Verordnung des Landes Baden-Württemberg
                </a>
            </div>
        );
    }
}

export default Content;
