import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import ScrollParallax from 'rc-scroll-anim/lib/ScrollParallax';
import BannerImage from './BannerImage';

import './Banner.less';

interface BannerProps {
}

interface BannerStates {
    isMobile: boolean;
}

class Banner extends Component<BannerProps, BannerStates> {

    constructor(props: BannerProps) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    };

    resize() {
        this.setState({isMobile: window.innerWidth <= 768});
    }

    render() {
        return (
            <div>
                <div className='royalRanger-wrapper royalRangerBanner-wrapper' id='royalRangerBanner'>
                    <div className='royalRangerBanner-bg-wrapper'>
                        <ScrollParallax location='royalRangerBanner' className='royalRangerBanner-bg' animation={{ playScale: [1, 1.5], rotate: 0 }} />
                    </div>
                    <QueueAnim className={`royalRangerBanner page`} type='alpha' delay={150}>
                        {this.state.isMobile && (
                            <div className='img-wrapper' key='image'>
                            </div>)}
                        <QueueAnim className='text-wrapper' type='bottom'>
                                <div>
                                    <h1 className='white' key='h1'>
                                        Royal Rangers
                                    </h1>
                                    <p className='white' key='p'>
                                        Jeden Samstag von 10:00 – 12:00 Uhr<br />
                                        auf der Rangerwiese an der Haltestelle Heusteigstrasse.<br/>
                                        Bei Interesse oder Fragen kannst du dich gerne per Email an<br/>
                                        <u><a href='mailto:rr407@gospelcenter.de'>rr407@gospelcenter.de</a></u><br/>
                                        wenden.
                                    </p>
                                </div>
                        </QueueAnim>
                                <div className={this.state.isMobile? 'img-wrapper-mobile': 'img-wrapper'}>
                                    <ScrollParallax location='royalRangerBanner' component={BannerImage} animation={{ playScale: [0.1, 0.1], y: 30 }} />
                                </div>
                        </QueueAnim>
                </div>

            </div>
        );
    }
}

export default Banner;
