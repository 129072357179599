import React, { Component } from 'react';
import { Typography, /*Modal*/ } from 'antd'; //, Button
// @ts-ignore
//import cookie from 'react-cookie';

import './Content.less';
//import moment from 'moment';

// @ts-ignore
import pdf_dso from '../../../assets/pdfs/datenschutzordnung.pdf';
// @ts-ignore
import pdf_imp from '../../../assets/pdfs/impressum.pdf';

const { Title, Text } = Typography;
//const { success, info } = Modal;


class Content extends Component {
/*
    showDeleteConfirm = () => {

        if (cookie.load('ga-disable-UA-141215149-1') === undefined) {
            cookie.save('ga-disable-UA-141215149-1', 'true', { path: '/', expires: moment().add(99, 'years').toDate()});
            window['ga-disable-UA-141215149-1'] = true;

            success({
                title: 'Deaktivierung des Website-Trackings',
                content: 'Google Analytics wurde deaktiviert',
                onOk() {}
            });
        } else {
            info({
                title: 'Deaktivierung des Website-Trackings',
                content: 'Der Opt-Out-Cookie zur Deaktivierung von Google Analytics wurde bereits gesetzt',
                onOk() {},
            });
        }
    };
*/
    render() {

        return (
            <div className='datenschutz__wrapper'>

                <Title level={3}>Geltungsbereich</Title>
                <Text className='datenschutz__margin-bottom'>
                    Diese Datenschutzerklärung klärt Nutzer über die Art, den Umfang und Zwecke der Erhebung und Verwendung personenbezogener Daten durch das <a href={pdf_imp} target='_blank' rel='noopener noreferrer'>Gospel Center Böblingen</a> auf dieser Website auf. Die rechtlichen Grundlagen des Datenschutzes finden sich in der EU-Datenschutzgrundverordnung (EU) 2016/679 (DS-GVO), im Bundesdatenschutzgesetz (BDSG), dem Telemediengesetz (TMG), sowie in der <a href={pdf_dso} target='_blank' rel='noopener noreferrer'>Datenschutzordnung</a> des Gospel Centers Böblingen e.V. (Kurz DSO des GCB)
                </Text>

                <Title level={3}>1. Datenschutz auf einen Blick</Title>
                <Title level={4}>Allgemeine Hinweise</Title>
                <Text className='datenschutz__margin-bottom'>
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </Text>

                <Title level={4}>Datenerfassung auf dieser Website</Title>
                <Text strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
                </Text>

                <Text strong>Wie erfassen wir Ihre Daten?</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.<br/>
                    <br/>
                    Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
                </Text>

                <Text strong>Wofür nutzen wir Ihre Daten?</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                </Text>

                <Text strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.<br/>
                    Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                </Text>

                <Text strong>Analyse-Tools und Tools von Drittanbietern</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen.<br/>
                    <br/>
                    Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
                </Text>

                <Title level={3}>2. Hosting und Content Delivery Networks (CDN)</Title>
                <Title level={4}>Externes Hosting</Title>
                <Text className='datenschutz__margin-bottom'>
                    Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige Daten, die über eine Website generiert werden, handeln.<br/>
                    <br/>
                    Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden § 5Abs. 2 lit. c DSO des GCB ,  vgl auch(Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch(Art. 6 Abs. 1 lit. f DSGVO).
                </Text>   

                <Text strong>Wir setzen folgenden Hoster ein:</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Telekom Deutschland GmbH, Landgrabenweg 151, 53227 Bonn
                </Text>
                <Text className='datenschutz__margin-bottom'>
                    Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
                </Text>

                <Text strong><br/>Abschluss eines Vertrages über Auftragsverarbeitung</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.
                </Text>

                <Title level={3}>3. Allgemeine Hinweise und Pflichtinformationen</Title>
                <Title level={4}>Datenschutz</Title>
                <Text className='datenschutz__margin-bottom'>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br/>
                    <br/>
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.<br/>
                    <br/>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </Text>

                <Title level={4}>Hinweis zur verantwortlichen Stelle</Title>
                <Text className='datenschutz__margin-bottom'>
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/>
                    <br/>
                    Gospel Center Böblingen e. V.<br/>
                    Sindelfinger Straße 35<br/>
                    71032 Böblingen<br/>
                    <br/>
                    Telefon: 07031 / 41 21 06<br/>
                    E-Mail: <a href='mailto:info@gospelcenter.de'>info@gospelcenter.de</a><br/>
                    <br/>
                    Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                </Text>
                
                <Title level={4}>Speicherdauer</Title>
                <Text className='datenschutz__margin-bottom'>
                    Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                </Text>

                <Title level={4}>Datenschutzbeauftragter</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.<br/>
                    <br/>

                    Ralf Tumat<br/>
                    Wolfsklingenweg 15<br/>
                    71364 Winnenden<br/>
                    <br/>
                    Telefon: +49(0)162 7511498<br/>
                    E-Mail: <a href='mailto:ralf.tumat@rtq-beratung.de'>ralf.tumat@rtq-beratung.de</a><br/>
                </Text>

                <Title level={4}>Hinweis zur Datenweitergabe in die USA</Title>
                <Text className='datenschutz__margin-bottom'>
                    Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogenen Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die USA kein sicherer Drittstaat im Sinne des EU- Datenschutzrechts sind. US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
                </Text>

                <Title level={4}>Widerruf zur Einwilligung zur Datenverarbeitung</Title>
                <Text className='datenschutz__margin-bottom'>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </Text>

                <Title level={4}>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
                    Direktwerbung § 16 der DSO des GCB vgl.(Art. 21 DSGVO)</Title>
                <Text strong className='datenschutz__margin-bottom'>
                    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH § 16 der DSO des GCB vgl. ( ART. 21 ABS. 1 DSGVO).<br/>
                    <br/>
                    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH§ 16 der DSO des GCB vgl. auch ART. 21 ABS. 2 DSGVO).
                </Text>

                <Title level={4}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</Title>
                <Text className='datenschutz__margin-bottom'>
                Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Datenschutzrat des Gospel Centers Böblingen e.V.. Sie können diesen folgendermaßen erreichen:<br/>
                    <br/>
                    Gospel Center Böblingen e.V.<br/>
                    Datenschutzrat<br/>
                    Sindelfingerstr. 35<br/>
                    71032 Böblingen<br/>
                    Telefon: 07031 - 412106<br/>
                    <br/>
                    E-Mail: <a href='mailto:datenschutzrat@gospelcenter.de'>datenschutzrat@gospelcenter.de</a><br/>
                </Text>

                <Title level={4}>Recht auf Datenübertragbarkeit</Title>
                <Text className='datenschutz__margin-bottom'>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </Text>

                <Title level={4}>SSL- bzw. TLS-Verschlüsselung</Title>
                <Text className='datenschutz__margin-bottom'>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/>
                    <br/>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                </Text>

                <Title level={4}>Auskunft, Löschung und Berichtigung</Title>
                <Text className='datenschutz__margin-bottom'>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                </Text>

                <Title level={4}>Recht auf Einschränkung der Verarbeitung</Title>
                <Text className='datenschutz__margin-bottom'>
                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                </Text>
                <ul className='datenschutz__margin-bottom'>
                    <li>
                        <Text>
                            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
                            <br/>
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.<br/>
                            <br/>
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
                            <br/>
                        </Text>
                    </li>
                    <li>
                        <Text>
                            Wenn Sie einen Widerspruch nach § 16 der DSO des GCB vgl. auch Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        </Text>
                    </li>
                </ul>
                <Text className='datenschutz__margin-bottom'>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                </Text>

                <Title level={3}>4. Datenerfassung auf dieser Website</Title>
                <Title level={4}>Cookies</Title>
                <Text className='datenschutz__margin-bottom'>
                    Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.<br/>
                    <br/>
                    Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).<br/>
                    <br/>
                    Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.<br/>
                    <br/>
                    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der Webseite (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch(Art. 6 Abs. 1 lit. f DSGVO).gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung § 5 Abs. 2 lit b DSO des GCB vgl. auch (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.<br/>
                    <br/>
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.<br/>
                    <br/>
                    Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.<br/>
                    <br/>
                </Text>

                <Title level={4}>Server-Log-Dateien</Title>
                <Text className='datenschutz__margin-bottom'>
                   Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:<br/>
                   <br/>
                   <ul className='datenschutz__margin-bottom'>
                       <li>
                           <Text>
                               Browsertyp und Browserversion
                           </Text>
                       </li>
                       <li>
                           <Text>
                               Verwendetes Betriebssystem
                           </Text>
                       </li>
                       <li>
                           <Text>
                               Referrer URL
                           </Text>
                       </li>
                       <li>
                           <Text>
                               Hostname des zugreifenden Rechners
                               <br/>
                           </Text>
                       </li>
                       <li>
                           <Text>
                               IP-Adresse
                           </Text>
                       </li>
                       <li>
                           <Text>
                               Uhrzeit der Serveranfrage
                           </Text>
                       </li>
                    </ul>
                    <br/>
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch(Art. 6 Abs. 1 lit. f DSGVO). Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website - hierzu müssen die Server-Log-Files erfasst werden.<br/>
                    <br/>
                </Text>

                <Title level={4}>Anfrage per E-Mail, Telefon oder Telefax</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/>
                    <br/>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von§ 5Abs. 2 lit. c DSO des GCB, vgl auch(Art. 6 Abs. 1 lit. b DSGVO), sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch(Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung  § 5 Abs. 2 lit b DSO des GCB vgl. auch (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde<br/>
                    <br/>
                    Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                </Text>

                <Title level={3}>5. Analyse-Tools und Werbung</Title>
                <Title level={4}>Google Analytics</Title>
                <Text className='datenschutz__margin-bottom'><br/>
                    Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.<br/>
                    <br/>
                    Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren.
                    Hierbei erhält der Websitebetreiber verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer,
                    verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem Profil
                    zusammengefasst, das dem jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist.<br/>
                    <br/>
                    Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber verschiedene Nutzungsdaten, wie z.B. Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem Profil zusammengefasst, das dem jeweiligen Nutzer bzw. dessen Endgerät zugeordnet ist<br/>
                    <br/>
                    Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch(Art. 6 Abs. 1 lit. f DSGVO). Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl seinWebangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von § 5 Abs. 2 lit b DSO des GCB vgl. auch (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.<br/>
                    <br/>
                    Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommision gestützt. 
                    Details finden Sie hier:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://privacy.google.com/businesses/controllerterms/mccs'> https://privacy.google.com/businesses/controllerterms/mccs</a>.
                </Text>

                <Text strong>IP Anonymisierung</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP- Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                </Text>

                <Text strong>Browser Plugin</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://tools.google.com/dlpage/gaoptout?hl=de'> https://tools.google.com/dlpage/gaoptout?hl=de</a><br/>
                    Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/analytics/answer/6004245?hl=de'>https://support.google.com/analytics/answer/6004245?hl=de</a>
                </Text>

                <Text strong>Auftragsverarbeitung</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengenVorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
                </Text>

                <Text strong>Demografische Merkmale bei Google Analytics</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Diese Website nutzt die Funktion „demografische Merkmale“ von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt „Widerspruch gegen Datenerfassung“ dargestellt generell untersagen.
                </Text>

                <Text strong>Speicherdauer</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B. DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter folgendem Link:<br />
                    <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/analytics/answer/7667196?hl=de'>https://support.google.com/analytics/answer/7667196?hl=de</a>
                </Text>

                <Title level={3}>6. Plugins und Tools</Title>
                <Title level={4}>Google Maps</Title>
                <Text className='datenschutz__margin-bottom'>
                    Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland. Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.<br/>
                    <br/>
                    Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von  § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch (Art. 6 Abs. 1 lit. f DSGVO) dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von  § 5 Abs. 2 lit b DSO des GCB vgl. auch (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.<br/>
                    <br/>
                    Die Datenschutzübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommision gestützt. Details finden Sie hier:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://privacy.google.com/businesses/gdprcontrollerterms'>https://privacy.google.com/businesses/gdprcontrollerterms</a> und<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://privacy.google.com/businesses/gdprcontrollerterms/sccs'>https://privacy.google.com/businesses/gdprcontrollerterms/sccs</a>.<br/>
                    <br/>
                    Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://policies.google.com/privacy?hl=de'>https://policies.google.com/privacy?hl=de</a>.
                </Text>
                
                <Title level={3}>7. eCommerce und Zahlungsanbieter</Title>
                <Title level={4}>Zahlungsdienste</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wir binden Zahlungsdienste von Drittunternehmen auf unserer Website ein. Wenn Sie einen Kauf bei uns tätigen, werden Ihre Zahlungsdaten (z.B. Name, Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom Zahlungsdienstleister zum Zwecke der Zahlungsabwicklung verarbeitet. Für diese Transaktionen gelten die jeweiligen Vertrags- und Datenschutzbestimmungen der jeweiligen Anbieter. Der Einsatz der Zahlungsdienstleister erfolgt auf Grundlage von § 5Abs. 2 lit. c DSO des GCB  vgl auch (Art. 6 Abs. 1 lit. b DSGVO) (Vertragsabwicklung) sowie im Interesse eines möglichst reibungslosen, komfortablen und sicheren Zahlungsvorgangs § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch (Art. 6 Abs. 1 lit. f DSGVO). Soweit für bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist § 5 Abs. 2 lit b DSO des GCB vgl. auch (Art. 6 Abs. 1 lit. a DSGVO) Rechtsgrundlage der Datenverarbeitung; Einwilligungen sind jederzeit für die Zukunft widerrufbar.<br/>
                    <br/>
                    Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im Rahmen dieser Website ein:    
                </Text>

                <Text strong>PayPal</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Anbieter dieses Zahlungsdienstes ist PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (im Folgenden „PayPal“).<br/>
                    <br/>
                    Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full'>https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full</a>.<br/>
                    <br/>
                    Details entnehmen Sie der Datenschutzerklärung von PayPal:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.paypal.com/de/webapps/mpp/ua/privacy-full'>https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>.
                </Text>
                
                <Title level={3}>8. Audio- und Videokonferenzen</Title>
                <Title level={4}>Datenverarbeitung</Title>
                <Text className='datenschutz__margin-bottom'>
                    Für die Kommunikation mit unseren Kunden setzen wir unter anderen Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder Audiokonferenz via Internet kommunizieren, werden Ihre personenbezogenen Daten von uns und dem Anbieter des jeweiligen Konferenz-Tools erfasst und verarbeitet.<br/>
                    <br/>
                    Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der Konferenz,Beginn und Ende (Zeit) der Teilnahme an der Konferenz, Anzahl der Teilnehmer und sonstige„Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang (Metadaten).<br/>
                    <br/>
                    Des Weiteren verarbeitet der Anbieter des Tools alle technischen Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen, Geräte-Ids, Gerätetyp, Betriebssystemtyp und -version, Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die Art der Verbindung.<br/>
                    <br/>
                    Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten zählen insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und andere Informationen, die während der Nutzung des Dienstes geteilt werden. Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung durch die Konferenztools entnehmen Sie den Datenschutzerklärungen der jeweils eingesetzten Tools, die wir unter diesem Text aufgeführt haben.
                </Text>

                <Title level={4}>Zweck und Rechtsgrundlagen</Title>
                <Text className='datenschutz__margin-bottom'>
                    Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden Vertragspartnern zu kommunizieren oder bestimmte Leistungen gegenüber unseren Kunden anzubieten§ 5Abs. 2 lit. c DSO des GCB  vgl auch (Art. 6 Abs. 1 lit. b DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen Vereinfachung und Beschleunigung der Kommunikation mit uns bzw. unserem Unternehmen (berechtigtes Interesse im Sinne von § 5 Abs.2 lit. g  der DSO des GCB, vgl. auch(Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der betreffenden Tools auf Grundlage dieser Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die Zukunft widerrufbar.
                </Text>

                <Title level={4}>Speicherdauer</Title>
                <Text className='datenschutz__margin-bottom'>
                    Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben unberührt.<br/>
                    <br/>
                    Auf die Speicherdauer Ihrer Daten, die von den Betreibern der Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der Konferenz-Tools.
                </Text>

                <Title level={4}>Eingesetzte Konferenz-Tools</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wir setzen folgende Konferenz-Tools ein:<br />
                </Text>
                <Text strong>Zoom</Text><br/>
                <Text className='datenschutz__margin-bottom'>
                    Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor, San Jose, CA 95113, USA.<br/>
                    Details zur Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Zoom:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://zoom.us/de-de/privacy.html'>https://zoom.us/de-de/privacy.html</a>.<br/>
                    <br/>
                    Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt.<br/>
                    Details finden Sie hier:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://zoom.us/de-de/privacy.html'>https://zoom.us/de-de/privacy.html</a>.
                </Text>

                <Title level={4}>Abschluss eines Vertrags über Auftragsverarbeitung</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wir haben mit dem Anbieter von Zoom einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Zoom vollständig um.
                </Text>

                <Title level={3}>9. Unsere Social-Media-Auftritte</Title>
                <Text className='datenschutz__margin-bottom'>
                    Diese Datenschutzerklärung gilt für folgende Social-Media-Auftritte:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/@GospelCenterBoblingen/about'>https://www.youtube.com/@GospelCenterBoblingen/about</a><br/>
                </Text>
                <br/><br/>
                <Title level={4}>Datenverarbeitung durch soziale Netzwerke</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wir unterhalten öffentlich zugängliche Profile in sozialen Netzwerken. Die im Einzelnen von uns genutzten sozialen Netzwerke finden Sie weiter unten.<br/>
                    <br/>
                    Soziale Netzwerke wie Facebook, Twitter etc. können Ihr Nutzerverhalten in der Regel umfassend analysieren, wenn Sie deren Website oder eine Website mit integrierten Social-Media-Inhalten (z. B. Like-Buttons oder Werbebannern) besuchen. Durch den Besuch unserer Social-Media-Präsenzen werden zahlreiche datenschutzrelevante Verarbeitungsvorgänge ausgelöst. Im Einzelnen:<br/>
                    <br/>
                    Wenn Sie in Ihrem Social-Media-Account eingeloggt sind und unsere Social-Media-Präsenz besuchen, kann der Betreiber des Social-Media-Portals diesen Besuch Ihrem Benutzerkonto zuordnen. Ihre personenbezogenen Daten können unter Umständen aber auch dann erfasst werden, wenn Sie nicht eingeloggt sind oder keinen Account beim jeweiligen Social-Media-Portal besitzen. Diese Datenerfassung erfolgt in diesem Fall beispielsweise über Cookies, die auf Ihrem Endgerät gespeichert werden oder durch Erfassung Ihrer IP-Adresse.<br/>
                    <br/>
                    Mit Hilfe der so erfassten Daten können die Betreiber der Social-Media-Portale Nutzerprofile erstellen, in denen Ihre Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann Ihnen interessenbezogene Werbung in- und außerhalb der jeweiligen Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account beim jeweiligen sozialen Netzwerk verfügen, kann die interessenbezogene Werbung auf allen Geräten angezeigt werden, auf denen Sie eingeloggt sind oder eingeloggt waren.<br/>
                    <br/>
                    Bitte beachten Sie außerdem, dass wir nicht alle Verarbeitungsprozesse auf den Social-Media-Portalen nachvollziehen können. Je nach Anbieter können daher ggf. weitere Verarbeitungsvorgänge von den Betreibern der Social-Media-Portale durchgeführt werden. Details hierzu entnehmen Sie den Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen Social-Media-Portale
                </Text>

                <Title level={4}>Rechtsgrundlage</Title>
                <Text className='datenschutz__margin-bottom'>
                    Unsere Social-Media-Auftritte sollen eine möglichst umfassende Präsenz im Internet gewährleisten. Hierbei handelt es sich um ein berechtigtes Interesse im Sinne von § 5 Abs. 2 lit. g  der DSO des GCB, vgl. auch (Art. 6 Abs. 1 lit. f DSGVO). Die von den sozialen Netzwerken initiierten Analyseprozesse beruhen ggf. auf abweichenden Rechtsgrundlagen, die von den Betreibern der sozialen Netzwerke anzugeben sind (z. B. Einwilligung im Sinne des § 5 Abs. 2 lit b DSO des GCB vgl. auch (Art. 6 Abs. 1 lit. a DSGVO)).
                </Text>

                <Title level={4}>Verantwortlicher und Geltendmachung von Rechten</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wenn Sie einen unserer Social-Media-Auftritte (z. B. Facebook) besuchen, sind wir gemeinsam mit dem Betreiber der Social-Media-Plattform für die bei diesem Besuch ausgelösten Datenverarbeitungsvorgänge verantwortlich. Sie können Ihre Rechte (Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl ggü. uns als auch ggü. dem Betreiber des jeweiligen Social-Media-Portals (z. B. ggü. Facebook) geltend machen.<br/>
                    <br/>
                    Bitte beachten Sie, dass wir trotz der gemeinsamen Verantwortlichkeit mit den Social-Media-Portal-Betreibern nicht vollumfänglich Einfluss auf die Datenverarbeitungsvorgänge der Social-Media-Portale haben. Unsere Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik des jeweiligen Anbieters.
                </Text>

                <Title level={4}>Speicherdauer</Title>
                <Text className='datenschutz__margin-bottom'>
                    Die unmittelbar von uns über die Social-Media-Präsenz erfassten Daten werden von unseren Systemen gelöscht, sobald Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. Zwingende gesetzliche Bestimmungen - insb. Aufbewahrungsfristen - bleiben unberührt.<br/>
                    <br/>
                    Auf die Speicherdauer Ihrer Daten, die von den Betreibern der sozialen Netzwerke zu eigenen Zwecken gespeichert werden, haben wir keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern der sozialen Netzwerke (z. B. in deren Datenschutzerklärung, siehe unten).
                </Text>

                <Title level={4}>Ihre Rechte</Title>
                <Text className='datenschutz__margin-bottom'>
                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Ihnen steht außerdem ein Recht auf Widerspruch, auf Datenübertragbarkeit und ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Ferner können Sie die Berichtigung, Sperrung, Löschung und unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen.
                </Text>

                <Title level={4}>Soziale Netzwerke im Einzelnen</Title>
                <Title level={5}>YouTube</Title>
                <Text className='datenschutz__margin-bottom'>
                    Wir verfügen über ein Profil bei YouTube. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Details zu deren Umgang mit Ihren personenbezogenen Daten entnehmen Sie der Datenschutzerklärung von YouTube:<br/>
                    <a target='_blank' rel='noopener noreferrer' href='https://policies.google.com/privacy?hl=de'>https://policies.google.com/privacy?hl=de</a>
                </Text>
            </div>
        );
    }
}

export default Content;
