import React, { Component } from 'react';

import LegalModal from '../../components/globalFooter/legalModal/LegalModal';

import './GlobalNews.less';
// @ts-ignore
//import  pdf_isk from '../../assets/pdfs/infektionsschutzkonzept.pdf';

// @ts-ignore
//import pdf_ahk from '../../assets/pdfs/abendteuerhajk.pdf';
// @ts-ignore
//import pdf_gfz from '../../assets/pdfs/gemeindefreizeit.pdf';


interface GlobalNewsProps {
}
interface GlobalNewsStates {
    showLivestreamLayer: boolean,
    showRegistrationLayer: boolean,
    visibleLegalModal: boolean,
    activeTab: string
}

class GlobalNews extends Component<GlobalNewsProps, GlobalNewsStates> {

    constructor(props: GlobalNewsProps) {
        super(props);
        this.state = {
            showLivestreamLayer: true,
            showRegistrationLayer: true,
            visibleLegalModal: false,
            activeTab: '3'
        };
    };

    handleLegal = (tab: string) => {
        this.setState({
            visibleLegalModal: true,
            activeTab: tab
        });
    };

    componentDidMount() {
        this.showRegistrationLayer();
    }

    showRegistrationLayer() {
        /*const key = `news-open${Date.now()}`;
        const btn = (
            <div>
                <Button type='primary' onClick={() => {
                    notification.close(key);
                }}>
                    Medlung schließen
                </Button>
            </div>
        );

        const icon = (<InfoCircleTwoTone />);
        //<AnchorLink href='#anchor-ahk'>Abendteuer-Hajk</AnchorLink>

        if (!(window.innerWidth <= 1024) && this.state.showRegistrationLayer) {
            const args = {
                message: 'Aktuelle Events',
                description:
                    [
                        <div key="current-events-main">
                            <span>
                                <br />   
                                In der nächsten Zeit gibt es wieder eineige <b>Events und Highlights:</b><br />
                                <br />
                                <b>Worship night</b> am Karfreitag, den <b>15. April um 19:00 Uhr</b> mit viel Musik. Komm gerne vorbei und mach mit! Falls Du nicht vorort sein kannst, gibt es auch einen <a href='https://www.youtube.com/channel/UCGMhlGs4i5jrA4hc5SuLQkw'> Livestream <YoutubeFilled style={{color: '#ff0000'}}/></a><br />
                                <br />
                                Ein <a href={pdf_ahk} aria-label='Abendteuer-Hajk' target='_blank' rel='noopener noreferrer'>Abendteuer-Hajk</a> findet von <b>Donnerstag, 21. April bis Sonntag, 24.April statt</b><br />
                                <br />
                                Die <a href={pdf_gfz} aria-label='Gemeindefreizeit' target='_blank' rel='noopener noreferrer'>Gemeindefreizeit</a> geht vom <b>08. Juli bis zum 10. Juli 2022</b><br />
                                <br />
                                Unser <b>Hauptgottesdienst</b> immer <b>sonntags um 10:00 Uhr</b> im Gospel Center in der Sindelfinger Straße 35 in Böblingen statt.<br />
                                <br />
                                Vorort stehen <b>genügend freie Plätze</b> unter Einhaltung des <Button className='no-padding' type='link' onClick={() => this.handleLegal('3')}>Infektionsschutzes</Button> bereit. Aktuell gilt <b>keine Begrenzung</b> der maximalen Besucherzahl und auch <b>keine Anmeldepflicht</b> sowie <b>keine Maskenpflicht</b> mehr!<br />
                                <br />
                                <br />
                                <b>Komm gerne vorbei und sei herzlich Willkommen!</b><br />
                            </span>
                            <br />
                            <br />
                        </div>
                    ],
                duration: 0,
                btn,
                key,
                icon,
                top: 80,
                onClose: () => {
                    this.setState({
                        showRegistrationLayer: false,
                    });
                },
            };
            notification.config({
                placement: 'topRight',
            });
        }*/
    }
    

    render() {
        const {visibleLegalModal, activeTab} = this.state;
        return(
            <div>
                <LegalModal activeTab={activeTab} visibleLegalModal={visibleLegalModal}/>
            </div>
        );
    }
}

export default GlobalNews;
