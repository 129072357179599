import React, { Component } from 'react';
import QueueAnim from 'rc-queue-anim';
import ScrollParallax from 'rc-scroll-anim/lib/ScrollParallax';

import './Banner.less';

interface BannerProps {
}

class Banner extends Component<BannerProps> {

    render() {
        return (
            <div>
                <div className='shop-wrapper shopBanner-wrapper' id='shopBanner'>
                    <div className='shopBanner-bg-wrapper'>
                        <ScrollParallax location='shopBanner' className='shopBanner-bg' animation={{ playScale: [1, 1.5], rotate: 0 }} />
                    </div>
                    <QueueAnim className={`shopBanner page`} type='alpha' delay={150}>
                        <div className='img-wrapper' key='image'>
                        </div>
                    </QueueAnim>
                </div>

            </div>
        );
    }
}

export default Banner;
