import React, { Component } from 'react';
import { Typography } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import './Leader.less';

import leftpadImg from '../../../../assets/svg/comments-leftpad-abd23.svg';
import rrLeader from '../../../../assets/images/royal-ranger/rrLeader.png';

const { Text } = Typography;

interface LeaderProps {
}

interface LeaderStates {
    isMobile: boolean;
}

class Leader extends Component<LeaderProps, LeaderStates> {

    constructor(props: LeaderProps) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    };

    resize() {
        this.setState({isMobile: window.innerWidth <= 768});
    }

    /*
    renderLeaderImage()
    {
        let r_img;
        if( !this.state.isMobile )
        {
            r_img=
                <div className='page mobile'>
                    <img src={rrLeader} alt='Royal Ranger Böblingen Leitung'/>
                </div>
            ;
        }
        else
        {
            r_img=
            (
                <div >
                    <img src={rrLeader} alt='Royal Ranger Böblingen Leitung'/>
                </div>
            );
        }
        return r_img;
    }*/
    render() {
        return (
            <OverPack component='section' className='page'>
                <QueueAnim
                    type='bottom'
                    leaveReverse
                    key='a'
                >
                    <h2>Leiterschaft</h2>
                    <div>
                        <div>
                            <div className={this.state.isMobile ? 'mobile': ''}>
                                <img src={rrLeader} alt='Royal Ranger Böblingen Leitung'/>
                            </div>
                            <div>
                                vlnr. Susanne Hauser (Hauptstammwart); Danny und Janina Mack (Hauptstammleiter)<br />
                                <br />
                                <br />
                            </div>
                        </div>
                        <div>
                            <Text className='block-text'>
                                Wir lieben es Kinder und Jugendlichen von Jesus zu erzählen, Sie in ihren Gaben und Fähigkeiten zu sehen als auch weiterzubringen, gemeinsam in der Natur Abenteuer erleben und jeden Einzelnen in seiner Persönlichkeitsentfaltung zu begleiten.<br />
                                Unser Versprechen:<br />
                                <br />
                            </Text>
                            <div>
                                {
                                       !this.state.isMobile
                                    && <img src={leftpadImg} alt=''/>
                                }
                                <Text className='comment'>
                                    Mit Gottes Hilfe wollen wir unser Bestes tun,<br />
                                    um Gott, unserer Gemeinde und unseren Mitmenschen zu dienen,<br />
                                    die Royal Rangers-Regeln zu halten<br />
                                    und die Goldene Regel zu unserem täglichen Leitspruch zu machen.
                                </Text>
                                {
                                       !this.state.isMobile
                                    && <img src={leftpadImg} alt=''/>
                                }
                            </div>
                        </div>
                    </div>
                    <br />
                </QueueAnim>
            </OverPack>
        );
    }
}

export default Leader;
