import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './BooksTable.less';

const { Text } = Typography;

interface BooksTableProps {
}

interface BooksTableStates {
    isMobile: boolean;
}

class BooksTable extends Component<BooksTableProps, BooksTableStates> {
    render() {
        return (
            <section id='booksTable' className='page-wrapper booksTable'>
                <div className='page' >
                    <div className='booksTable--header-wrapper'>
                        <QueueAnim className='booksTable--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Büchertisch</h2>
                            <div key='line' className='title-line-wrapper booksTable-line'>
                                <div className='title-line' />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3' className='booksTable--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>
                                    Jeden Sonntag vor und nach dem Gottesdienst bieten wir aktuelle Bücher, Musik-CDs,<br/>
                                    DVDs und Geschenkartikel für Kinder und Erwachsene an.<br/>
                                    Außerdem verkaufen wir die Predigt-CDs unserer Gottesdienste.<br/>
                                    Sie können gerne alle Artikel direkt am Büchertisch bestellen.<br/>
                                    Kommen Sie vorbei und stöbern Sie – <br/>
                                    <strong>wir beraten Sie gerne.</strong>
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </section>
        );
    }
}

export default BooksTable;
