import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/es/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';

import './KnowUs.less';

const { Text } = Typography;

interface KnowUsProps {
}

interface KnowUsStates {
    isMobile: boolean;
}

class KnowUs extends Component<KnowUsProps, KnowUsStates> {
    render() {
        return (
            <section id='knowUs' className='page-wrapper knowUs'>
                <div className='page' >
                    <div className='knowUs--header-wrapper'>
                        <QueueAnim className='knowUs--header-wrapper-anim' type='bottom'>
                            <h2 key='header'>Lerne uns kennen</h2>
                            <div key='line' className='title-line-wrapper knowUs-line'>
                                <div className='title-line' />
                            </div>
                        </QueueAnim>
                    </div>
                    <ScrollOverPack playScale='0.3' className='knowUs--wrapper'>
                        <QueueAnim
                            component={Row}
                            key='queue'
                            type='bottom'
                            ease={['easeOutQuart', 'easeInQuart']}
                            leaveReverse
                        >
                            <Col
                                key='1'
                                {... {name: 'block',
                                    className: 'block',
                                    md: 24,
                                    xs: 24}
                                }>
                                <Text className='block-text'>
                                    Du bist neu in unserer Gemeinde und möchtest uns besser kennen lernen?<br/>
                                    Dann möchten wir dich gerne zu unserem Next-Steps-Seminar einladen.<br/>
                                    Hier besteht die Möglichkeit an vier Sonntagen, nach dem Gottesdienst die Gemeindestruktur,<br/>
                                    unsere Lehre und Dienste kennen zu lernen uns so selbst seinen Platz zu finden um einen Unterschied zu machen.<br/>
                                    <br/>
                                    Die nächsten Seminar Sonntage sind: <strong>In Planung!</strong>
                                </Text>
                            </Col>
                        </QueueAnim>
                    </ScrollOverPack>
                </div>
            </section>
        );
    }
}

export default KnowUs;
